<div>
    <div class="field">
        <p-dropdown appendTo="body" [(ngModel)]="selectedDevice" [options]="availableDevices"
            placeholder="Seleccione una camara"></p-dropdown>
    </div>
    <div class="field" style="position: relative;">
        <label for="qr">Escanea tu código QR de Artículo <span class="req">*</span></label>
        <zxing-scanner [(device)]="selectedDevice" (camerasFound)="onCamerasFound($event)"
            (scanSuccess)="handleQrCodeResult($event)" [start]="true">
        </zxing-scanner>
        <div *ngIf="codeInProgress" class="overlay"></div>
    </div>
    <div class="field">
        <p-messages [(value)]="messages" [enableService]="false" [closable]="false" />
    </div>
    <div *ngIf="qrResultString">
        <div class="field">
            <p-chip [label]="'Código: '+qrResultString" icon="pi pi-code" (onRemove)="resetQrFields()"
                [removable]="true" />
        </div>
        <div class="field">
            <input type="text" pInputText id="name" [(ngModel)]="serialNumber" placeholder="Serial Number (Opcional)"
                autofocus />
        </div>
        <div *ngIf="type == constants.QUANTITY">
            <div class="field">
                <label for="quantity">Cantidad de artículos <span class="req">*</span></label>
                <p-inputNumber id="quantity" inputId="integeronly" [(ngModel)]="quantity" [min]="1" [max]="100000"
                    [showButtons]="true" />
            </div>
            <div class="field">
                <p-button size="large" icon="pi pi-caret-right" label="Guardar artículos" (onClick)="onFinishQuantityScan()" />
            </div>
        </div>

        <div *ngIf="type == constants.ARTICLE" class="field">
            <p-button size="large" icon="pi pi-caret-right" label="Guardar artículo"
                (onClick)="readNextCode()" />
        </div>
    </div>
    <div *ngIf="scannedItems.length > 0 && type == constants.ARTICLE">
        <div class="field">
            <label>Cantidad de artículos escaneados: {{scannedItems.length}}</label>
        </div>
        <div class="field">
            <p-button severity="info" icon="pi pi-stop-circle" label="Terminar Escaneo" (onClick)="onFinishItemScan()" />
        </div>
    </div>
</div>