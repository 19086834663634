import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Item, ItemSearchParams, PageableItems2 } from '../domain/inventory.model';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private apiUrl = environment.apiUrl + '/api/v1/item';

  constructor(private http: HttpClient) { }

  validateSimpleCode(warehouse: string, code: string): Observable<boolean> {
    const params = new HttpParams()
      .set('code', code);
    return this.http.get<boolean>(`${this.apiUrl}/${warehouse}/validate`, { params });
  }

  getItems(warehouse: string, page: number, size: number, sortField: string, sortOrder: string, filters: string): Observable<PageableItems2> {
    const params = {
      page: page.toString(),
      size: size.toString(),
      sort: sortField,
      order: sortOrder
    };

    return this.http.get<PageableItems2>(`${this.apiUrl}/${warehouse}`, { params });
  }

  searchItems(params: ItemSearchParams): Observable<{ content: Item[]; totalElements: number }> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        httpParams = httpParams.set(key, value.toString());
      }
    });

    return this.http.get<{ content: Item[]; totalElements: number }>(`${this.apiUrl}/search/${params['warehouse']}`, { params: httpParams });
  }

}
