<div class="scanner-container p-grid p-fluid">
    <p-panel>
        <ng-template pTemplate="header">
            <div class="custom-header">
                <span class="header-title">Escaneo de nuevos Artículos</span>
                <button pButton icon="pi pi-times" class="p-button-rounded p-button-text" (click)="onClose()"></button>
            </div>
        </ng-template>

        <div class="content">
            <!-- View Messages -->
            <p-toast [showTransformOptions]="'translateX(-100%)'" [showTransitionOptions]="'1000ms'"
                [hideTransitionOptions]="'500ms'" [showTransformOptions]="'translateY(100%)'"
                [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }" />

            <!-- Selección del modo de escaneo -->
            <div class="p-col-12 mb-2">
                <label for="inventoryType">Tipo de Inventario <span class="req">*</span></label>
                <p-dropdown
                    [options]="[{label: 'Escaneo por Artículo', value: 'item'}, {label: 'Escaneo por Cantidades', value: 'quantity'}]"
                    [(ngModel)]="scanningMode">
                </p-dropdown>
            </div>

            <!-- Campo de descripción -->
            <div class="p-col-12 mb-2">
                <label for="description">Descripción <span class="req">*</span></label>
                <input id="description" [(ngModel)]="description" id="description" type="text" pInputText
                    placeholder="Descripción del artículo" />
            </div>

            <!-- Campo de SKU-->
            <div class="p-col-12 mb-2">
                <label for="serialNumber">SKU</label>
                <input id="serialNumber" [(ngModel)]="serialNumber" id="serialNumber" type="text" pInputText
                    placeholder="Serial Number" />
            </div>

            <!-- Campo de cantidad (visible solo si el modo es por cantidades) -->
            <div class="p-col-12 mb-2" *ngIf="scanningMode === 'quantity'">
                <label for="quantity">Cantidad de artículos <span class="req">*</span></label>
                <p-inputNumber [(ngModel)]="quantity" placeholder="Cantidad" [min]="1" [max]="100000"
                    inputId="integeronly" [showButtons]="true"></p-inputNumber>
            </div>

            <!-- QR Scanner -->
            <div class="p-col-12 mb-2">
                <h6>Escanear Etiqueta Simple</h6>
                <p-menu #menu [model]="cameraMenuItems" [popup]="true" />
                <zxing-scanner (click)="menu.toggle($event)" (tap)="menu.toggle($event)" [device]="selectedDevice"
                    (scanSuccess)="onScanQr($event)"></zxing-scanner>
            </div>

            <!-- Lista de artículos escaneados -->
            <div class="p-col-12 mb-5">
                <div class="color-legend">
                    <p><span class="legend-color item-scan"></span> Escaneo por Artículo</p>
                    <p><span class="legend-color quantity-scan"></span> Escaneo por Cantidad</p>
                </div>
                <p-table [value]="scannedItems" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>QR</th>
                            <th>Descripción</th>
                            <th>#</th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-i="rowIndex" let-editing="editing">
                        <tr>
                            <td [ngClass]="{'item-scan': item.type === constants.ARTICLE, 'quantity-scan': item.type === constants.QUANTITY}">{{ item.code }}</td>
                            <td [pEditableColumn]="item.description" pEditableColumnField="Descripción">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="item.description" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ item.description }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td [pEditableColumn]="item.quantity" pEditableColumnField="Cantidad">
                                <p-cellEditor *ngIf="item.type == constants.QUANTITY">
                                    <ng-template pTemplate="input">
                                        <p-inputNumber [(ngModel)]="item.quantity" [min]="1" [max]="100000" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ item.quantity }}
                                    </ng-template>
                                </p-cellEditor>
                                <p *ngIf="item.type == constants.ARTICLE">
                                    {{ item.quantity }}
                                </p>
                            </td>
                            <td>
                                <p-button icon="pi pi-trash" severity="danger" [outlined]="true"
                                    (click)="removeItem(i)"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <!-- Botones de acción -->
            <div class="p-col-12 flex justify-content-between">
                <p-button label="Paletizar" icon="pi pi-box" severity="secondary" (click)="onPaletize()"
                    [disabled]="!scannedItems.length"></p-button>
                <p-button label="Guardar" icon="pi pi-save" (click)="onSave()"
                    [disabled]="!scannedItems.length"></p-button>
            </div>
        </div>
    </p-panel>
</div>
<p-confirmDialog></p-confirmDialog>