<h2>Crear Orden</h2>
<!--Table-->
<div class="card">
    <p-stepper [linear]="true">
        <p-stepperPanel>
            <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
                <button class="bg-transparent border-none inline-flex flex-column gap-2 p-stepper-action"
                    (onClick)="onClick.emit()">
                    <span class="p-stepper-number">1</span><span class="p-stepper-title text-base">Paso 1</span><span
                        class="p-stepper-title font-light text-xs">Datos del Cliente</span>
                </button>
            </ng-template>
            <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                <form [formGroup]="orderForm">

                    <h4>Datos del cliente</h4>
                    <div class="formgrid grid">
                        <div class="field col-12 md:col-2">
                            <label for="service_type">Tipo de servicio <span class="req">*</span></label>
                            <p-dropdown formControlName="service_type" id="service_type" appendTo="body"
                                [options]="serviceTypes" optionLabel="name" optionValue="name" [showClear]="true"
                                placeholder="Selecciona un tipo" [(ngModel)]="order.service_type" autofocus required
                                styleClass="p-inputtext-sm w-full"></p-dropdown>
                            <small class="p-error"
                                *ngIf="submitted && (formControl['service_type'].errors?.['required'] || formControl['service_type'].errors?.['whitespace'] || formControl['service_type'].errors?.['minlength'])">Tipo
                                de servicio es requerido.</small>
                        </div>

                        <div class="field col-12 md:col-3">
                            <label for="account_name">Cuenta <span class="req">*</span></label>
                            <p-dropdown formControlName="account_name" id="account_name" appendTo="body"
                                [options]="accounts" [(ngModel)]="order.account_name" optionLabel="name"
                                optionValue="name" [filter]="true" filterBy="name" [showClear]="true"
                                placeholder="Selecciona una cuenta" styleClass="p-inputtext-sm w-full">
                                <ng-template pTemplate="selectedItem" let-selectedOption>
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ order.account_name }}</div>
                                    </div>
                                </ng-template>
                                <ng-template let-order pTemplate="item">
                                    <div class="flex align-items-center gap-2">
                                        <div>{{ order.name }}</div>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                            <small class="p-error"
                                *ngIf="submitted && (formControl['account_name'].errors?.['required'] || formControl['account_name'].errors?.['whitespace'] || formControl['account_name'].errors?.['minlength'])">Cuenta
                                es requerido.</small>
                        </div>

                        <div class="field col-12 md:col-2">
                            <label for="contact_method">Tipo de contacto <span class="req">*</span></label>
                            <p-dropdown formControlName="contact_method" id="contact_method" appendTo="body"
                                [options]="contactTypes" optionLabel="name" optionValue="name" [showClear]="true"
                                placeholder="Selecciona un tipo" [(ngModel)]="order.contact_method" required
                                styleClass="p-inputtext-sm w-full"></p-dropdown>
                            <small class="p-error"
                                *ngIf="submitted && (formControl['contact_method'].errors?.['required'] || formControl['contact_method'].errors?.['whitespace'] || formControl['contact_method'].errors?.['minlength'])">Tipo
                                de contacto es requerido.</small>
                        </div>

                        <div class="field col-12 md:col-2">
                            <label for="contact_value">Correo <span class="req">*</span></label>
                            <input formControlName="contact_value" type="contact_value" pInputText id="contact_value"
                                required class="p-inputtext-sm w-full" />
                            <small class="p-error"
                                *ngIf="submitted && formControl['contact_value'].errors?.['required']">Contacto es
                                requerido.</small>
                        </div>

                        <div class="field col-12 md:col-3">
                            <label for="authorized_person">Persona Autorizada <span class="req">*</span></label>
                            <input formControlName="authorized_person" type="authorized_person" pInputText
                                id="authorized_person" required class="p-inputtext-sm w-full" />
                            <small class="p-error"
                                *ngIf="submitted && formControl['authorized_person'].errors?.['required']">Contacto es
                                requerido.</small>
                        </div>
                    </div>

                    <div class="formgrid grid">
                        <div class="field col-12 md:col-1">
                            <label for="zip_code">C.P. <span class="req">*</span></label>
                            <input formControlName="zip_code" type="text" pInputText id="zip_code"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-2">
                            <label for="state">Estado <span class="req">*</span></label>
                            <input formControlName="state" type="text" pInputText id="state"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-2">
                            <label for="municipality">Delegación/Municipio <span class="req">*</span></label>
                            <input formControlName="municipality" type="text" pInputText id="municipality"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-2">
                            <label for="neighborhood">Colonia <span class="req">*</span></label>
                            <input formControlName="neighborhood" type="text" pInputText id="neighborhood"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-2">
                            <label for="street_name">Calle <span class="req">*</span></label>
                            <input formControlName="street_name" type="text" pInputText id="street_name"
                                class="p-inputtext-sm w-full" />
                        </div>


                        <div class="field col-12 md:col-1">
                            <label for="exterior_number">Num Ext. <span class="req">*</span></label>
                            <input formControlName="exterior_number" type="text" pInputText id="exterior_number"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-1">
                            <label for="interior_number">Num Int.</label>
                            <input formControlName="interior_number" type="text" pInputText id="interior_number"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-1">
                            <label for="floor">Num Piso</label>
                            <input formControlName="floor" type="text" pInputText id="floor"
                                class="p-inputtext-sm w-full" />
                        </div>

                        <div class="field col-12 md:col-3">
                            <label for="appointment_datetime">Fecha y Hora de Cita <span class="req">*</span></label>
                            <p-calendar [(ngModel)]="date" formControlName="appointment_datetime"
                                [iconDisplay]="'input'" [showIcon]="true" [readonlyInput]="true" [showTime]="true"
                                [hourFormat]="'12'" [minDate]="minDate" class="p-inputtext-sm w-full"></p-calendar>
                        </div>

                        <div class="field col-12 md:col-3">
                            <label for="adress_notes">Notas de entrega</label>
                            <textarea formControlName="adress_notes" rows="3" cols="30" pInputTextarea
                                class="p-inputtext-sm w-full"></textarea>
                        </div>
                        
                    </div>


                </form>




                <div class="flex pt-4 justify-content-end">
                    <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()" />
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel>
            <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
                <button class="bg-transparent border-none inline-flex flex-column gap-2 p-stepper-action"
                    (onClick)="onClick.emit()">
                    <span class="p-stepper-number">2</span><span class="p-stepper-title text-base">Paso 2</span><span
                        class="p-stepper-title font-light text-xs">Inventario</span>
                </button>
            </ng-template>
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                let-index="index">
                <div class="flex flex-column h-12rem">
                    <div
                        class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        Content II</div>
                </div>
                <div class="flex pt-4 justify-content-between">
                    <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                    <p-button label="Next" icon="pi pi-arrow-right" iconPos="right" (onClick)="nextCallback.emit()" />
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel>
            <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
                <button class="bg-transparent border-none inline-flex flex-column gap-2 p-stepper-action"
                    (onClick)="onClick.emit()">
                    <span class="p-stepper-number">3</span><span class="p-stepper-title text-base">Paso 3</span><span
                        class="p-stepper-title font-light text-xs">Resumen</span>
                </button>
            </ng-template>
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                <div class="flex flex-column h-12rem">
                    <div
                        class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        Content III</div>
                </div>
                <div class="flex pt-4 justify-content-start">
                    <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                </div>
            </ng-template>
        </p-stepperPanel>
        <p-stepperPanel>
            <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
                <button class="bg-transparent border-none inline-flex flex-column gap-2 p-stepper-action"
                    (onClick)="onClick.emit()">
                    <span class="p-stepper-number">4</span><span class="p-stepper-title text-base">Paso 4</span><span
                        class="p-stepper-title font-light text-xs">Confirmación</span>
                </button>
            </ng-template>
            <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
                <div class="flex flex-column h-12rem">
                    <div
                        class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                        Content IV</div>
                </div>
                <div class="flex pt-4 justify-content-start">
                    <p-button label="Back" icon="pi pi-arrow-left" (onClick)="prevCallback.emit()" />
                </div>
            </ng-template>
        </p-stepperPanel>
    </p-stepper>
</div>