import { Component } from '@angular/core';
import { SimpleLabel } from '../../../domain/simple_label.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AbstractControl, ValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SimpleLabelService } from '../../../services/simple-label.service';
import { AuthService } from '../../../services/auth.service';
import { Warehouse } from '../../../domain/warehouse.model';

@Component({
  selector: 'app-simple-labels',
  standalone: false,
  templateUrl: './simple-labels.component.html',
  styleUrl: './simple-labels.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class SimpleLabelsComponent {
  generateLabelsNumber: number = 1;
  labelDialog: boolean = false;
  printDialog: boolean = false;
  printLabelsFrom: number = 1;
  printLabelsTo: number = 1;
  labelsCounter: number = 0;
  printLabelSubmitted:boolean = false;
  selectedWarehouse: SimpleLabel = {};
  printLabelForm: FormGroup = this.formBuilder.group({
    printLabelsFrom: ['', Validators.required],
    printLabelsTo: ['', Validators.required],
  }, { validator: this.compareValuesValidator() });
  
  simple_labels_array: SimpleLabel[] = [];
  warehouses: Warehouse[] = [
    {
      id: 1,
      name: 'Bodega Zapopan'
    }
  ];

  constructor(
    private messageService: MessageService, 
    private confirmationService: ConfirmationService, 
    private formBuilder: FormBuilder,
    private simpleLabelService: SimpleLabelService,
    private authService: AuthService) {
   }

   get isBildAdmin(): boolean {
    return this.authService.isBildAdmin();
   }

  ngOnInit(): void { 
    this.getAllSimpleLabels();
  }

  getAllSimpleLabels() {
    this.simpleLabelService.getAll().subscribe(
      (data: SimpleLabel[]) => {
        this.simple_labels_array = data;
      }
    );
  }

  get formControl(): any {
    return this.printLabelForm.controls;
  }

  onCreateNewLabels() {    
    this.generateLabelsNumber = 1;
    this.setLabelRanges();
    this.labelDialog = true;
  }

  generateNewLabels() {
    this.confirmationService.confirm({
      header: 'Generar Etiquetas',
      message: 'Esta seguro que desea crear ' + this.generateLabelsNumber + ' etiquetas?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-sm',
      acceptButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.simpleLabelService.incrementCounter(this.selectedWarehouse.simpleLabelId || -1, this.generateLabelsNumber).subscribe(
          () => {
            this.hideNewLabelDialog();
            this.printDialog = true;
            this.selectedWarehouse.counter = (this.selectedWarehouse.counter || 0 ) + this.generateLabelsNumber;
            this.getAllSimpleLabels();
            this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Etiquetas creadas', life: 3000 });
          }
        );
      }
    });
  }

  onLabelQuantityChange(): void {
    this.setLabelRanges();
  }

  hideNewLabelDialog() {
    this.labelDialog = false;  
  }

  onPrintLabels() {
    this.labelsCounter = this.selectedWarehouse.counter || 0;
    this.printLabelsFrom = this.selectedWarehouse.counter || 0 ;
    this.printLabelsTo = this.selectedWarehouse.counter || 0;
    this.printDialog = true;
  }

  setLabelRanges() {
    this.labelsCounter = (this.selectedWarehouse.counter || 0) + this.generateLabelsNumber;
    this.printLabelsFrom = (this.labelsCounter - this.generateLabelsNumber) + 1;
    this.printLabelsTo = this.labelsCounter;
  }

  get calculatePrintRange(): { [key: string]: boolean } {
    if (this.printLabelsFrom > this.printLabelsTo) {
      //From greater than To
      return { 'invalidRange': true };
    }
    if ( ((this.printLabelsTo+1) - this.printLabelsFrom) > 100) {
      return { 'maxExceeded': true };;
    }
  return { 'invalidRange': false };;
  }

  hidePrintLabelsDialog() {
    this.printDialog = false;
  }

  printLabels() {
    this.printLabelSubmitted = true;
    if (this.printLabelForm.valid){
      this.simpleLabelService.printLabels(this.selectedWarehouse.simpleLabelId || -1, this.printLabelsFrom, this. printLabelsTo).subscribe(
        (response: ArrayBuffer) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error) => {
          console.error('Error generating labels', error);
        }
      );
    }
  }

  compareValuesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const from = control.get('printLabelsFrom');
      const to = control.get('printLabelsTo');
  
      if (from && to && from.value > to.value) {
        return { 'invalidRange': true };
      }
      if (((to?.value+1) - from?.value) > 100) {
        return { 'maxExceeded': true };
      }
      return null;
    };
  }
}
