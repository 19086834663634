import { Account } from './account.model';

export interface Inventory {
    id: number;
    item: Item;
    itemGroup: ItemGroup;
}

export interface Item {
    itemId: number;
    serialNumber: string;
    code: string;
    status: string;
    user: string;
    palletCode?: string;
    creationDate: Date;
    releaseDate: Date;
}

export interface NewItem {
    serialNumber: string;
    code: string;
    description: string;
    quantity?: number;
    palletCode?: string;
    type?: number;
    account?: Account;
    warehouse?: string;
}

export interface Pallet {
    palletId?: number;
    depth?: number;
    width?: number;
    height?: number;
    notes?: string
    flooring?: string;
    qrCode?: string;
    location?: string;
    account?: Account;
    warehouse?: string;
    status?: string;
    creationDate?: string;
    updateDate?: string;
}

export interface NewPallet {
    depth?: number;
    width?: number;
    height?: number;
    notes?: string
    flooring?: string;
    qrCode?: string;
    location?: string;
    account?: Account;
    warehouse?: string;
}

export interface PalletCreation {
    pallet: NewPallet;
    items: Item[]
}

export interface ItemGroup {
    groupId: number;
    description: string;
    quantity: number;
    type: string;
    warehouse: string;
    account: Account;
}

export interface PageableItems {
    totalElements: number;
    content: Inventory[];
}

export interface PageableItems2 {
    totalElements: number;
    content: Item[];
}

export interface ItemSearchParams {
    warehouse?: string;
    accountId?: number;
    code?: string;
    description?: string;
    palletCode?: string;
    status?: string;
    creationDateFrom?: string;
    creationDateTo?: string;
    page: number;
    size: number;
    sortBy: string;
    sortDirection: 'ASC' | 'DESC';
}

export interface PalletSearchParams {
    warehouse?: string;
    accountId?: number;
    palletCode?: string;
    status?: string;
    flooring?: string;
    location?: string;
    creationDateFrom?: string;
    creationDateTo?: string;
    page: number;
    size: number;
    sortBy: string;
    sortDirection: 'ASC' | 'DESC';
}

export interface InventoryCreation {
    items: NewItem[],
    pallet: NewPallet | undefined,
    warehouse: string;
    accountId: number;
}


export enum Constants {
    ARTICLE = 1,
    QUANTITY = 2,
}