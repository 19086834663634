import { Component } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Constants, Item, NewItem } from '../../domain/inventory.model';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { debounceTime, Subject } from 'rxjs';
import { ItemService } from '../../services/item.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';

@Component({
  selector: 'app-qr-scanner-v2',
  templateUrl: './qr-scanner-v2.component.html',
  styleUrl: './qr-scanner-v2.component.scss',
  providers: [ConfirmationService]
})
export class QrScannerV2Component {
  scannedItems: NewItem[] = [];
  description: string = '';
  scanningMode: 'item' | 'quantity' = 'item';
  serialNumber: string = '';
  quantity: number = 1;
  palletData: any = {};
  warehouse = '';
  accountId = 0;
  constants = Constants;
  private qrSearchSubject = new Subject<string>();

  // Camera settings
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined;
  cameraMenuItems: MenuItem[] = [];

  constructor(private scanner: ZXingScannerComponent, private messageService: MessageService, private itemService: ItemService,
    private location: Location, private route: ActivatedRoute, private inventoryService: InventoryService, private router: Router,
    private confirmationService: ConfirmationService
  ) {
    this.warehouse = this.route.snapshot.paramMap.get('warehouse') || '';
    this.accountId = Number(this.route.snapshot.paramMap.get('accountId'));
    this.qrSearchSubject.pipe(
      debounceTime(1000)
    ).subscribe(result => {
      this.onScanSuccess(result);
    });
  }

  ngOnInit() {
    this.scanner.askForPermission();
    this.listCameras();
    this.setHistoryState();
  }

  ngOnDestroy(): void {
    window.removeEventListener('popstate', this.onBack.bind(this));
  }

  private setHistoryState(): void {
    history.pushState(null, '');
    window.addEventListener('popstate', this.onBack.bind(this));
  }

  listCameras(): void {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.availableDevices = devices.filter(device => device.kind === 'videoinput');
      this.populateCameraMenu();
      if (this.availableDevices.length > 0) {
        this.selectedDevice = this.availableDevices[0];
      }
    });
  }

  populateCameraMenu(): void {
    this.cameraMenuItems = this.availableDevices.map((device, index) => ({
      label: device.label || `Cámara ${index + 1}`,
      command: () => this.selectCamera(device)
    }));
    this.cameraMenuItems = [
      {
        label: 'Cámaras',
        items: this.cameraMenuItems
      }
    ];
  }

  selectCamera(device: MediaDeviceInfo): void {
    this.selectedDevice = device;
  }

  playSound() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/beepSound2.mp3';
    audio.load();
    audio.play();
  }

  onScanQr(qrCode: string) {
    this.qrSearchSubject.next(qrCode);
  }

  onScanSuccess(qrCode: string) {
    this.playSound();
    if (this.scannedItems.some(item => item.code === qrCode)) {
      this.messageService.add({ severity: 'warn', summary: 'Código duplicado', detail: 'Este código ya esta en la lista' });
      return;
    }

    if (!this.description.trim()) {
      this.messageService.add({ severity: 'error', summary: 'Descripción requerida', detail: 'Agrega una descripción antes de escanear' });
      return;
    }

    this.itemService.validateSimpleCode(this.warehouse, qrCode).subscribe(isValid => {
      if (isValid) {
        const newItem: NewItem = {
          code: qrCode,
          serialNumber: this.serialNumber,
          description: this.description,
          quantity: this.scanningMode === 'item' ? 1 : this.quantity,
          type: this.scanningMode === 'item' ? Constants.ARTICLE : Constants.QUANTITY,
          warehouse: this.warehouse,
          account: { 'accountId': this.accountId },
          palletCode: ''
        };

        this.scannedItems.push(newItem);
        this.messageService.add({ severity: 'success', summary: 'Escaneado', detail: 'Artículo agregado' });
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Código utilizado', detail: 'Este código ya fue utilizado' });
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  removeItem(index: number) {
    this.scannedItems.splice(index, 1);
  }

  onSave() {
    this.inventoryService.createArticles(this.scannedItems, this.warehouse, this.accountId).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Inventario creado', detail: 'Se han creado los Artículos', life: 5000 });
      this.router.navigate(['/home/inventory/articles']);
    }, resp => {
      console.error('Error creating the Articles', resp);
      this.messageService.add({ severity: 'error', summary: resp.error.message });
    });
  }

  onBack() {
    history.pushState(null, '');
  }

  onClose() {
    if (this.scannedItems.length) {
      this.confirmationService.confirm({
        message: 'Los artículos escaneados aún no se han guardado. ¿Deseas salir sin guardar?',
        header: 'Confirmación',
        acceptLabel: 'Sí',
        rejectLabel: 'No',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          window.removeEventListener('popstate', this.onBack.bind(this));
          this.router.navigate(['/home/inventory/articles']);
          //this.location.back();
        }
      });
    } else {
      this.router.navigate(['/home/inventory/articles']);
    }
      
  }

  onPaletize() {
    this.inventoryService.createArticles(this.scannedItems, this.warehouse, this.accountId).subscribe((itemsData: Item) => {
      this.router.navigate(['/pallet-scanner'], {
        queryParams: {
          warehouse: this.warehouse,
          accountId: this.accountId
        },
        state: { items: itemsData }
      });
    }, resp => {
      console.error('Error creating the Articles', resp);
      this.messageService.add({ severity: 'error', summary: resp.error.message });
    });
  }
}
