<div class="pallet-container p-grid p-fluid">
    <p-panel>
        <ng-template pTemplate="header">
            <div class="custom-header">
                <span class="header-title">Paletizar Articulos</span>
                <button pButton icon="pi pi-times" class="p-button-rounded p-button-text" (click)="onClose()"></button>
            </div>
        </ng-template>

        <div class="content">
            <!-- View Messages -->
            <p-toast [showTransformOptions]="'translateX(-100%)'" [showTransitionOptions]="'1000ms'"
                [hideTransitionOptions]="'500ms'" [showTransformOptions]="'translateY(100%)'"
                [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }" />

            <div class="articles_content" *ngIf="!paletize">
                <!-- QR Scanner -->
                <div class="p-col-12 mb-2">
                    <h6>Escanear Etiqueta Simple</h6>
                    <p-menu #menu [model]="cameraMenuItems" [popup]="true" />
                    <zxing-scanner (click)="menu.toggle($event)" (tap)="menu.toggle($event)" [device]="selectedDevice"
                        (scanSuccess)="onArticleScanSuccess($event)"></zxing-scanner>
                </div>

                <!-- Lista de artículos escaneados -->
                <div class="p-col-12 mb-5">
                    <ng-container *ngTemplateOutlet="tablaTemplate"></ng-container>
                </div>

                <!-- Botones de acción -->
                <div class="p-col-12">
                    <p-button label="Siguiente" icon="pi pi-fast-forward" (click)="paletize=true"
                        [disabled]="!scannedItems.length"></p-button>
                </div>
            </div>

            <div class="pallet_content" *ngIf="paletize">
                <!-- QR Scanner -->
                <div class="p-col-12 mb-2" *ngIf="!palletCode">
                    <h6>Escanear Etiqueta Pallet</h6>
                    <p-menu #menu [model]="cameraMenuItems" [popup]="true" />
                    <zxing-scanner (click)="menu.toggle($event)" (tap)="menu.toggle($event)" [device]="selectedDevice"
                        (scanSuccess)="onPalletScanSuccess($event)"></zxing-scanner>
                </div>
                <div class="p-col-12 mb-2" *ngIf="palletCode">
                    <p-chip [label]="palletCode" icon="" (onRemove)="resetPalletCode()"
                        [removable]="true"><strong>Código Pallet:</strong></p-chip>
                </div>

                <div class="field">
                    <p-button (click)="openCamera()" label="Tomar Foto" icon="pi pi-camera" [rounded]="true"
                        severity="info" [outlined]="true" />
                    <input type="file" id="cameraInput" accept="image/*" capture="environment"
                        (change)="onPhotoSelected($event)" style="display:none;" multiple>
                </div>

                <div class="field">
                    <ng-container *ngIf="selectedImages.length > 0">
                        <p-chip *ngFor="let image of selectedImages; let i = index" [label]="'Foto ' + (i+1)"
                            removable="true" (onRemove)="removeImage(i)" class="p-chip mb-1 ml-1"
                            styleClass="padding: 0 0.50rem;">
                            <img [src]="image" alt="Foto" style="width: 30px; height: 30px; margin-left: 2px;" />
                        </p-chip>
                    </ng-container>
                </div>

                <!-- Lista de artículos escaneados -->
                <div class="p-col-12 mb-5">
                    <ng-container *ngTemplateOutlet="tablaTemplate"></ng-container>
                </div>

                <!-- Botones de acción -->
                <div class="p-col-12">
                    <p-button label="Guardar" icon="pi pi-save" (click)="onSave()" [disabled]="!palletCode"></p-button>
                </div>
            </div>
        </div>
    </p-panel>
</div>

<ng-template #tablaTemplate>
    <div class="color-legend">
        <p><span class="legend-color item-scan"></span> Escaneo por Artículo</p>
        <p><span class="legend-color quantity-scan"></span> Escaneo por Cantidad</p>
    </div>
    <p-table [value]="scannedItems" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th>QR</th>
                <th>Descripción</th>
                <th>#</th>
                <th *ngIf="!paletize"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex" let-editing="editing">
            <tr>
                <td [ngClass]="{'item-scan': item.type === constants.ARTICLE, 'quantity-scan': item.type === constants.QUANTITY}">{{ item.code }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.quantity }}</td>
                <td *ngIf="!paletize">
                    <p-button icon="pi pi-trash" severity="danger" [outlined]="true" (click)="removeItem(i)"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</ng-template>
<p-confirmDialog></p-confirmDialog>