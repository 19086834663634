import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Pallet, PalletSearchParams } from '../domain/inventory.model';

@Injectable({
  providedIn: 'root'
})
export class PalletService {

  private apiUrl = environment.apiUrl + '/api/v1/pallet';

  constructor(private http: HttpClient) { }

  validatePalletCode(warehouse: string, accountId: number, code: string): Observable<boolean> {
    const params = new HttpParams()
      .set('code', code);
    return this.http.get<boolean>(`${this.apiUrl}/${warehouse}/${accountId}/validate`, { params });
  }

  searchItems(params: PalletSearchParams): Observable<{ content: Pallet[]; totalElements: number }> {
    let httpParams = new HttpParams();
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        httpParams = httpParams.set(key, value.toString());
      }
    });

    return this.http.get<{ content: Pallet[]; totalElements: number }>(`${this.apiUrl}/search/${params['warehouse']}`, { params: httpParams });
  }
}
