export interface Role {
    roleId?: number;
    name?: string;
    isDeleted?: boolean;
}

export enum RoleConstants {
    BILD_ADMIN = 'BILD_ADMIN',
    BILD_OPERADOR = 'BILD_OPERADOR', 
    BILD_ENCARGADO = 'BILD_ENCARGADO', 
    BILD_VENTAS = 'BILD_VENTAS', 
}

export type RoleAction = 'edit' | 'delete';

export type PermissionType = {
    edit: string[];
    delete: string[];
  };