<h2>Inventario</h2>
<!--Table-->
<div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4">
        <div class="grid col-12 ">
            <div class="col col-12 xl:col-3">
                <label for="warehouse" style="font-size: 12px; color: #6b7280">Bodega</label>
                <p-dropdown id="warehouse" [options]="warehouses" [(ngModel)]="selectedWarehouse" placeholder="Selecciona una bodega"
                optionLabel="name" [style]="{'width':'100%'}"></p-dropdown>
                    
            </div>
            <div class="col col-12 xl:col-3">
                <label for="account" style="font-size: 12px; color: #6b7280">Cuenta</label>
                <p-dropdown id="account" [options]="account_array" [(ngModel)]="selectedAccount" placeholder="Selecciona una cuenta"
                optionLabel="name" filter="true" showClear="true" filterPlaceholder="Buscar..."
                (ngModelChange)="onChangeAccount()" [disabled]="!hasAccountAccess" [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
            <div class="col col-12 xl:col-6 flex align-items-center justify-content-end">
                <p-button label="Agregar Artículos" icon="pi pi-plus" class="p-button-primary mr-2" (click)="onAddItems()"
                    [disabled]="!selectedAccount" *ngIf="hasActionAccess"></p-button>
                <p-button label="Paletizar Artículos" icon="pi pi-plus" class="p-button-primary" (click)="onPalletize()"
                [disabled]="!selectedAccount" *ngIf="hasActionAccess"></p-button>
            </div>
        </div>
    </p-toolbar>

    <div class="card">
        <p-table #dt [value]="items" rowGroupMode="rowspan" groupRowsBy="itemGroup.groupId" sortField="descripcion"
            sortMode="single" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" [tableStyle]="{'min-width': '75rem'}"
            [paginator]="true" [rows]="rows" [totalRecords]="totalRecords" [lazy]="true"
            (onLazyLoad)="loadItemsLazy($event)">
            <ng-template pTemplate="caption">
                <div class="grid col-12">
                    <div class="col col-12 xl:col-6">
                        <h4>Inventario de "{{selectedAccount.name}}" en {{
                            selectedWarehouse.name}}
                        </h4>
                    </div>
                    <div class="col col-12 xl:col-6 flex align-items-center justify-content-end">
                        <div class="formgroup-inline">
                            <div class="field">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" [(ngModel)]="searchValue" (ngModelChange)="onSearch()"
                                        placeholder="Buscar por descripción" class="p-column-filter"
                                        [style]="{'width':'100%'}">
                                </span>
                            </div>
                            <p-button label="Limpiar" [outlined]="true" icon="pi pi-filter-slash"
                                (onClick)="clear(dt)" />
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:3rem">#</th>
                    <th>Descripción</th>
                    <th>Código de artículo</th>
                    <th>Cantidad</th>
                    <th>Número de Serie</th>
                    <th>Código de pallet</th>
                    <th>Usuario</th>
                    <th>Fecha de creación</th>
                    <th>Fecha de salida</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-inventory let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                let-rowspan="rowspan">
                <tr>
                    <td>{{rowIndex + 1}}</td>
                    <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                        <span class="font-bold ml-2">{{inventory.itemGroup.description}}</span>
                        <p *ngIf="rowspan" class="text-xs ml-2">Total: {{inventory.itemGroup.quantity}}</p>
                    </td>
                    <td>
                        {{inventory.item.code}}
                    </td>
                    <td>
                        {{inventory.quantity}}
                    </td>
                    <td>
                        {{inventory.item.serialNumber}}
                    </td>
                    <td>
                        {{inventory.item.palletCode}}
                    </td>
                    <td>
                        {{inventory.item.user}}
                    </td>
                    <td>
                        {{inventory.item.creationDate | date:'medium'}}
                    </td>
                    <td>
                        {{inventory.item.releaseDate | date:'medium'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

<!-- confirm dialog-->
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>