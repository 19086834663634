import { Account } from './account.model';

export interface Inventory {
    id: number;
    item: Item;
    itemGroup: ItemGroup;
}

export interface Item {
    itemId: number;
    serialNumber: string;
    code: string;
    status: string;
    user: string;
    palletCode: string;
    creationDate: Date;
    releaseDate: Date;
}

export interface NewItem {
    serialNumber: string;
    code: string;
    description: string;
    quantity?: number;
    palletCode?: string;
    type?: number;
}

export interface NewPallet {
    depth?: number;
    width?: number;
    height?: number;
    notes?: string
    flooring?: string;
    file?: File;
    qrCode?: string;
    location?: string;
}

export interface ItemGroup {
    groupId: number;
    description: string;
    quantity: number;
    type: string;
    warehouse: string;
    account: Account;
}

export interface PageableItems {
    totalElements: number;
    content: Inventory[];
}

export interface InventoryCreation {
    items: NewItem[],
    pallet: NewPallet,
    warehouse: string;
    accountId: number;

}

export enum Constants {
    ARTICLE = 1,
    QUANTITY = 2, 
}