<!-- Menu section -->
<p-menubar [model]="menuItems">
  <ng-template pTemplate="start">
    <img src="./assets/Bild-Portal.svg" height="40" class="mr-2" />
  </ng-template>
  <ng-template pTemplate="end">
    <p-menu #menu [model]="profileItems" [popup]="true"></p-menu>
    <div *ngIf="userDetails; else null">
      <div style="display: flex; flex-direction: column; align-items: flex-start;">
        <p-button [label]="userDetails.email" size="small" icon="pi pi-user" [text]="true"
          (click)="menu.toggle($event)">
        </p-button>
        <span class="role-label">{{ userDetails.role?.name }}</span> <!-- Rol alineado debajo del icono -->
      </div>
    </div>
    <!--<button pButton type="button" (click)="menu.toggle($event)" icon="pi pi-bars" label="Show"></button>-->
  </ng-template>
</p-menubar>
<p-toast></p-toast>
<!-- Content section -->
<div style="display: flex; flex-direction: column; min-height: 92vh;">
  <div style="flex-grow: 1; padding: 20px;">
    <router-outlet></router-outlet>
  </div>
  <!-- Footer section -->
  <footer class="footer">
    <div>
      <span>&copy; 2025 Build App. All Rights Reserved.</span>
    </div>
  </footer>
</div>
<!-- User profile edit-->
<app-user-profile [userProfileVisible]="userProfileVisible"
  (userSidebarClosed)="resetUserProfileVisibility()"></app-user-profile>