import { Component } from '@angular/core';
import { Warehouse } from '../../domain/warehouse.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, Subject } from 'rxjs';
import { Item, Pallet, PalletSearchParams } from '../../domain/inventory.model';
import { ItemService } from '../../services/item.service';
import { AccountService } from '../../services/account.service';
import { PalletService } from '../../services/pallet.service';
import { PageableAccount } from '../../domain/account.model';

@Component({
  selector: 'app-pallets-inventory',
  templateUrl: './pallets-inventory.component.html',
  styleUrl: './pallets-inventory.component.scss'
})
export class PalletsInventoryComponent {
  pallets: Pallet[] = [];
  accounts: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  filters: any = {};
  statuses = [
    { label: 'COMPLETADA', value: 'COMPLETADA' },
    { label: 'CREADA', value: 'CREADA' },
    { label: 'ELIMINADA', value: 'ELIMINADA' },
  ];
  floorings = [
    { label: 'Piso', value: 'Piso' },
    { label: 'Rack', value: 'Rack' },
  ];
  warehouses: Warehouse[] = [
    {
      id: 1,
      name: 'Bodega Zapopan'
    }
  ];

  //Account dropdown
  lastQuery: string = '';
  searchAccountSubject = new Subject<string>();
  searchAccountQuery: string = '';

  //Search form
  searchForm: FormGroup = this.fb.group({
    warehouse: [''],
    accountId: [null],
    palletCode: [null],
    status: [null],
    flooring: [null],
    location: [null],
    creationDateFrom: [null],
    creationDateTo: [null],
  });
  tableRows: number = 30;

  constructor(private palletService: PalletService, private fb: FormBuilder, private accountService: AccountService) { }

  ngOnInit(): void {
    this.searchForm.patchValue({ warehouse: this.warehouses[0].name });
    this.fetchAccounts();
    this.searchAccountSubject.pipe(debounceTime(800)).subscribe(searchText => {
      this.searchAccountQuery = searchText;
      this.fetchAccounts();
    });
  }

  fetchAccounts() {
    this.loading = true;
    this.accountService.searchAllAccounts(this.searchAccountQuery).subscribe((response: PageableAccount) => {
      this.accounts = response.content;
      this.loading = false;
    });
  }

  search() {
    this.loadItems({ first: 0, rows: this.tableRows });
  }
  loadAccounts(event: any) {
    this.searchAccountSubject.next(event.filter || '');
  }

  resetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({ warehouse: this.warehouses[0].name });
    //this.loadItems();
    //this.items = [];
  }

  loadItems(event: any) {
    this.loading = true;
    const params: PalletSearchParams = {
      ...this.searchForm.value,
      creationDateFrom: this.formatDate(this.searchForm.value.creationDateFrom),
      creationDateTo: this.formatDate(this.searchForm.value.creationDateTo),
      page: event.first / event.rows,
      size: event.rows,
      sortBy: 'creationDate',
      sortDirection: 'DESC',
      warehouse: this.searchForm.get('warehouse')?.value
    };

    this.palletService.searchItems(params).subscribe((data) => {
      this.pallets = data.content;
      this.totalRecords = data.totalElements;
      this.loading = false;
    });
  }

  private formatDate(date: Date | null): string | null {
    return date ? date.toISOString() : null;
  }

  getSeverity(status: string) {
    switch (status) {
      case 'COMPLETADA':
        return 'success';
      case 'CREADA':
        return 'info';
      case 'ELIMINADA':
        return 'warning';
      default:
        return '';
    }
  }

}
