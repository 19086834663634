import { Injectable } from '@angular/core';

import { NgxImageCompressService } from 'ngx-image-compress';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UploadResponse } from '../domain/uploadResponse.model';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private apiUrl = environment.apiUrl + '/api/v1/upload';

  constructor(private imageCompress: NgxImageCompressService, private http: HttpClient) { }

  compressImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.imageCompress.compressFile(event.target.result, -1, 50, 50).then(
          (compressedImage) => {
            fetch(compressedImage)
              .then(res => res.blob())
              .then(blob => {
                const compressedFile = new File([blob], file.name, { type: file.type });
                resolve(compressedFile);
              });
          },
          (error) => reject(error)
        );
      };
    });
  }

  uploadImages(files: File[], palletId: number | string): Observable<number[]> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    return this.http.post<number[]>(`${this.apiUrl}/pallet/image?palletId=` + palletId, formData);
  }

}
