import { Component } from '@angular/core';
import { Warehouse } from '../../../domain/warehouse.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccountDropdown } from '../../../domain/accountDropdown.model';
import { AccountService } from '../../../services/account.service';
import { InventoryService } from '../../../services/inventory.service';
import { PageableItems } from '../../../domain/inventory.model';
import { UploadResponse } from '../../../domain/uploadResponse.model';
import { TableLazyLoadEvent } from 'primeng/table';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Dropdown } from '../../../domain/dropdown.model';
import { Inventory, NewPallet } from '../../../domain/inventory.model';
import { AuthService } from '../../../services/auth.service';
import { Account } from '../../../domain/account.model';
import { ImageService } from '../../../services/image.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory',
  standalone: false,
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class InventoryComponent {
  private searchSubject = new Subject<string>();
  rows: number = 50;
  warehouses: Warehouse[] = [
    {
      id: 1,
      name: 'Bodega Zapopan'
    }
  ];
  selectedWarehouse: Warehouse = this.warehouses[0];
  items: Inventory[] = [];
  selectedAccount: AccountDropdown = { accountId: 0, name: '', isDeleted: false };
  account_array: AccountDropdown[] = [];
  totalRecords: number = 0;
  searchValue: string = '';
  userAccount: Account;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService,
    private inventoryService: InventoryService,
    private imageService: ImageService,
    private authService: AuthService,
    private router: Router
  ) {
    this.userAccount = authService.getUserAccount();
  }

  ngOnInit(): void {
    this.getAllAccounts();
    // Configure search debounce
    this.searchSubject.pipe(
      debounceTime(600)
    ).subscribe(searchValue => {
      this.initSearch();
    });
  }

  getAllAccounts() {
    this.accountService.getAllDropdownAccounts(this.hasAccountAccess ? undefined : this.userAccount).subscribe(
      (data: AccountDropdown[]) => {
        this.account_array = data;
        this.selectedAccount = data[0];
        this.initSearch();
      }
    );
  }

  getAccounts() {
    this.accountService.getAllDropdownAccounts(this.hasAccountAccess ? undefined : this.userAccount).subscribe(
      (data: AccountDropdown[]) => {
        this.account_array = data;
        this.selectedAccount = data[0];
        this.initSearch();
      }
    );
  }

  initSearch() {
    this.loadItemsLazy({ first: 0, rows: this.rows });
  }

  loadItemsLazy(event: TableLazyLoadEvent) {
    if (!this.selectedAccount?.accountId) { return; }
    let page: number = (event.first! / event.rows!) || 0;
    let size: number = event.rows || 0;

    if (this.searchValue) {
      this.searchItems(this.selectedWarehouse?.name, this.selectedAccount?.accountId, page, size, this.searchValue);
    } else {
      this.loadItems(this.selectedWarehouse?.name, this.selectedAccount?.accountId, page, size);
    }
  }

  loadItems(warehouse: string, accountId: number, page: number, size: number) {
    this.inventoryService.getItemsByWarehouseAndCompany(warehouse,
      accountId, page, size).subscribe(
        (data: PageableItems) => {
          this.items = data.content;
          this.totalRecords = data.totalElements;
        }
      );
  }

  searchItems(warehouse: string, accountId: number, page: number, size: number, description: string) {
    this.inventoryService.searchItemsByDescription(warehouse,
      accountId, page, size, description).subscribe(
        (data: PageableItems) => {
          this.items = data.content;
          this.totalRecords = data.totalElements;
        }
      );
  }

  onSearch() {
    this.searchSubject.next(this.searchValue);
  }

  clear(table: Table) {
    this.searchValue = '';
    this.initSearch();
  }

  onAddItems() {
    this.router.navigate(['/article-scanner', this.selectedWarehouse.name, this.selectedAccount.accountId]);
  }

  onPalletize() {
    this.router.navigate(['/palletization', this.selectedWarehouse.name, this.selectedAccount.accountId]);
  }

  onFinishScan(palletData: NewPallet) {
    /*this.inventoryService.create(inventoryCreation)
    .pipe(
      switchMap((response: NewPallet) => {
        const files: File[] = palletData.files || [];
        if (files.length > 0) {
          return this.imageService.uploadImages(files, response.palletId ?? '');
        }
        return of([]);
      })
    ).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Inventario creado', life: 5000 });
      this.addPalletDialog = false;
      this.isScanningFinished = true;
      this.initSearch();
    }, resp => {
      console.error('Error creating inventory', resp);
      this.messageService.add({ severity: 'error', summary: resp.error.message });
    });]*/

  }


  onChangeAccount() {
    this.initSearch();
  }

  get hasActionAccess(): boolean {
    return this.authService.canAccessAction('inventory', 'edit');
  }

  get hasAccountAccess(): boolean {
    return this.authService.canAccessAction('inventory', 'account');
  }

}
