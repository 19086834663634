import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i2 from 'primeng/inputtext';
import { InputTextModule } from 'primeng/inputtext';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
const _c0 = (a0, a1, a2) => ({
  $implicit: a0,
  events: a1,
  index: a2
});
function InputOtp_ng_container_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "input", 2);
    i0.ɵɵlistener("input", function InputOtp_ng_container_0_ng_container_1_Template_input_input_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const i_r2 = i0.ɵɵnextContext().$implicit;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onInput($event, i_r2 - 1));
    })("focus", function InputOtp_ng_container_0_ng_container_1_Template_input_focus_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onInputFocus($event));
    })("blur", function InputOtp_ng_container_0_ng_container_1_Template_input_blur_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onInputBlur($event));
    })("paste", function InputOtp_ng_container_0_ng_container_1_Template_input_paste_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onPaste($event));
    })("keydown", function InputOtp_ng_container_0_ng_container_1_Template_input_keydown_1_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r2 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r2.onKeyDown($event));
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("value", ctx_r2.getModelValue(i_r2))("maxLength", 1)("type", ctx_r2.inputType)("inputmode", ctx_r2.inputMode)("variant", ctx_r2.variant)("readonly", ctx_r2.readonly)("disabled", ctx_r2.disabled)("invalid", ctx_r2.invalid)("tabindex", ctx_r2.tabindex)("unstyled", ctx_r2.unstyled);
  }
}
function InputOtp_ng_container_0_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function InputOtp_ng_container_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, InputOtp_ng_container_0_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const i_r2 = i0.ɵɵnextContext().$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.inputTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction3(2, _c0, ctx_r2.getToken(i_r2 - 1), ctx_r2.getTemplateEvents(i_r2 - 1), i_r2));
  }
}
function InputOtp_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, InputOtp_ng_container_0_ng_container_1_Template, 2, 10, "ng-container", 1)(2, InputOtp_ng_container_0_ng_container_2_Template, 2, 6, "ng-container", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r2.inputTemplate);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r2.inputTemplate);
  }
}
const INPUT_OTP_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputOtp),
  multi: true
};
/**
 * Input Otp is used to enter one time passwords.
 * @group Components
 */
class InputOtp {
  cd;
  /**
   * When present, it specifies that the component should have invalid state style.
   * @group Props
   */
  invalid = false;
  /**
   * When present, it specifies that the component should be disabled.
   * @group Props
   */
  disabled = false;
  /**
   * When present, it specifies that an input field is read-only.
   * @group Props
   */
  readonly = false;
  /**
   * Specifies the input variant of the component.
   * @group Props
   */
  variant = null;
  /**
   * Index of the element in tabbing order.
   * @group Props
   */
  tabindex = null;
  /**
   * Number of characters to initiate.
   * @group Props
   */
  length = 4;
  /**
   * Mask pattern.
   * @group Props
   */
  mask = false;
  /**
   * When present, it specifies that an input field is integer-only.
   * @group Props
   */
  integerOnly = false;
  /**
   * Callback to invoke on value change.
   * @group Emits
   */
  onChange = new EventEmitter();
  /**
   * Callback to invoke when the component receives focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Callback to invoke when the component loses focus.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  templates;
  inputTemplate;
  tokens = [];
  onModelChange = () => {};
  onModelTouched = () => {};
  value;
  get inputMode() {
    return this.integerOnly ? 'numeric' : 'text';
  }
  get inputType() {
    return this.mask ? 'password' : 'text';
  }
  constructor(cd) {
    this.cd = cd;
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'input':
          this.inputTemplate = item.template;
          break;
        default:
          this.inputTemplate = item.template;
          break;
      }
    });
  }
  getToken(index) {
    return this.tokens[index];
  }
  getTemplateEvents(index) {
    return {
      input: event => this.onInput(event, index),
      keydown: event => this.onKeyDown(event),
      focus: event => this.onFocus.emit(event),
      blur: event => this.onBlur.emit(event),
      paste: event => this.onPaste(event)
    };
  }
  onInput(event, index) {
    this.tokens[index] = event.target.value;
    this.updateModel(event);
    if (event.inputType === 'deleteContentBackward') {
      this.moveToPrev(event);
    } else if (event.inputType === 'insertText' || event.inputType === 'deleteContentForward') {
      this.moveToNext(event);
    }
  }
  updateModel(event) {
    const newValue = this.tokens.join('');
    this.onModelChange(newValue);
    this.onChange.emit({
      originalEvent: event,
      value: newValue
    });
  }
  writeValue(value) {
    if (value) {
      if (Array.isArray(value) && value.length > 0) {
        this.value = value.slice(0, this.length);
      } else {
        this.value = value.toString().split('').slice(0, this.length);
      }
    } else {
      this.value = value;
    }
    this.updateTokens();
    this.cd.markForCheck();
  }
  updateTokens() {
    if (this.value !== null && this.value !== undefined) {
      if (Array.isArray(this.value)) {
        this.tokens = [...this.value];
      } else {
        this.tokens = this.value.toString().split('');
      }
    } else {
      this.tokens = [];
    }
  }
  getModelValue(i) {
    return this.tokens[i - 1] || '';
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  moveToPrev(event) {
    let prevInput = this.findPrevInput(event.target);
    if (prevInput) {
      prevInput.focus();
      prevInput.select();
    }
  }
  moveToNext(event) {
    let nextInput = this.findNextInput(event.target);
    if (nextInput) {
      nextInput.focus();
      nextInput.select();
    }
  }
  findNextInput(element) {
    let nextElement = element.nextElementSibling;
    if (!nextElement) return;
    return nextElement.nodeName === 'INPUT' ? nextElement : this.findNextInput(nextElement);
  }
  findPrevInput(element) {
    let prevElement = element.previousElementSibling;
    if (!prevElement) return;
    return prevElement.nodeName === 'INPUT' ? prevElement : this.findPrevInput(prevElement);
  }
  onInputFocus(event) {
    event.target.select();
    this.onFocus.emit(event);
  }
  onInputBlur(event) {
    this.onBlur.emit(event);
  }
  onKeyDown(event) {
    switch (event.code) {
      case 'ArrowLeft':
        this.moveToPrev(event);
        event.preventDefault();
        break;
      case 'ArrowUp':
      case 'ArrowDown':
        event.preventDefault();
        break;
      case 'Backspace':
        if (event.target.value.length === 0) {
          this.moveToPrev(event);
          event.preventDefault();
        }
        break;
      case 'ArrowRight':
        this.moveToNext(event);
        event.preventDefault();
        break;
      default:
        if (this.integerOnly && !((event.code.startsWith('Digit') || event.code.startsWith('Numpad')) && Number(event.key) >= 0 && Number(event.key) <= 9) || this.tokens.join('').length >= this.length && event.code !== 'Delete') {
          event.preventDefault();
        }
        break;
    }
  }
  onPaste(event) {
    let paste = event.clipboardData.getData('text');
    if (paste.length) {
      let pastedCode = paste.substring(0, this.length + 1);
      if (!this.integerOnly || !isNaN(pastedCode)) {
        this.tokens = pastedCode.split('');
        this.updateModel(event);
      }
    }
    event.preventDefault();
  }
  getRange(n) {
    return Array.from({
      length: n
    }, (_, index) => index + 1);
  }
  trackByFn(index) {
    return index;
  }
  static ɵfac = function InputOtp_Factory(t) {
    return new (t || InputOtp)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: InputOtp,
    selectors: [["p-inputOtp"]],
    contentQueries: function InputOtp_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-inputotp", "p-component"],
    inputs: {
      invalid: "invalid",
      disabled: "disabled",
      readonly: "readonly",
      variant: "variant",
      tabindex: "tabindex",
      length: "length",
      mask: "mask",
      integerOnly: "integerOnly"
    },
    outputs: {
      onChange: "onChange",
      onFocus: "onFocus",
      onBlur: "onBlur"
    },
    features: [i0.ɵɵProvidersFeature([INPUT_OTP_VALUE_ACCESSOR])],
    decls: 1,
    vars: 2,
    consts: [[4, "ngFor", "ngForOf", "ngForTrackBy"], [4, "ngIf"], ["type", "text", "pInputText", "", 1, "p-inputotp-input", 3, "input", "focus", "blur", "paste", "keydown", "value", "maxLength", "type", "inputmode", "variant", "readonly", "disabled", "invalid", "tabindex", "unstyled"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
    template: function InputOtp_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, InputOtp_ng_container_0_Template, 3, 2, "ng-container", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngForOf", ctx.getRange(ctx.length))("ngForTrackBy", ctx.trackByFn);
      }
    },
    dependencies: [i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i2.InputText],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputOtp, [{
    type: Component,
    args: [{
      selector: 'p-inputOtp',
      template: `
        <ng-container *ngFor="let i of getRange(length); trackBy: trackByFn">
            <ng-container *ngIf="!inputTemplate">
                <input
                    type="text"
                    pInputText
                    [value]="getModelValue(i)"
                    [maxLength]="1"
                    [type]="inputType"
                    class="p-inputotp-input"
                    [inputmode]="inputMode"
                    [variant]="variant"
                    [readonly]="readonly"
                    [disabled]="disabled"
                    [invalid]="invalid"
                    [tabindex]="tabindex"
                    [unstyled]="unstyled"
                    (input)="onInput($event, i - 1)"
                    (focus)="onInputFocus($event)"
                    (blur)="onInputBlur($event)"
                    (paste)="onPaste($event)"
                    (keydown)="onKeyDown($event)"
                />
            </ng-container>
            <ng-container *ngIf="inputTemplate">
                <ng-container *ngTemplateOutlet="inputTemplate; context: { $implicit: getToken(i - 1), events: getTemplateEvents(i - 1), index: i }"> </ng-container>
            </ng-container>
        </ng-container>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-inputotp p-component'
      },
      providers: [INPUT_OTP_VALUE_ACCESSOR]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    invalid: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    readonly: [{
      type: Input
    }],
    variant: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    length: [{
      type: Input
    }],
    mask: [{
      type: Input
    }],
    integerOnly: [{
      type: Input
    }],
    onChange: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class InputOtpModule {
  static ɵfac = function InputOtpModule_Factory(t) {
    return new (t || InputOtpModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: InputOtpModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule, InputTextModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputOtpModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SharedModule, InputTextModule],
      exports: [InputOtp, SharedModule],
      declarations: [InputOtp]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { INPUT_OTP_VALUE_ACCESSOR, InputOtp, InputOtpModule };
