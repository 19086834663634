import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NewPallet } from '../../domain/inventory.model';
import { InventoryService } from '../../services/inventory.service';
import { PalletLabelService } from '../../services/pallet-label.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-qr-pallet-scanner',
  templateUrl: './qr-pallet-scanner.component.html',
  styleUrl: './qr-pallet-scanner.component.scss'
})
export class QrPalletScannerComponent {
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined = undefined;
  deviceIndex = 1;
  hasDevices: boolean = false;
  codeInProgress: boolean = false;
  qrResultString: string = '';
  location: string;
  locations: string[];
  selectedImage: string | ArrayBuffer | null = null;
  showPalletForm: boolean = false;
  palletForm: FormGroup;
  submitted: boolean = false;
  type: number = 1;
  messages: Message[] = [];
  @Input() warehouse: string = '';
  @Input() accountId: number = 1;
  @Input() palletData: NewPallet = {};
  @Output() onFinish = new EventEmitter<NewPallet>();

  constructor(private scanner: ZXingScannerComponent, private inventoryService: InventoryService, private formBuilder: FormBuilder, private palletLabelService: PalletLabelService) {
    this.locations = inventoryService.getPalletLocation();
    this.location = this.locations[0];
    this.palletForm = this.formBuilder.group({
      depth: [0, Validators.required],
      width: [0, Validators.required],
      height: [0, Validators.required],
      notes: [''],
      file: [null],
      flooring: [''],
      location: [''],
    });
  }

  ngOnInit() {
    this.palletForm.patchValue({
      depth: this.palletData.depth || 0,
      width: this.palletData.width || 0,
      height: this.palletData.height || 0
    });
    this.scanner.askForPermission();
  }

  get formControl(): any {
    return this.palletForm.controls;
  }

  playSound() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/beepSound2.mp3';
    audio.load();
    audio.play();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.selectedDevice = this.availableDevices[0];
    this.hasDevices = Boolean(devices && devices.length);
  }

  handleQrCodeResult(result: string) {
    if (this.codeInProgress) { return; }
    this.playSound();
    this.codeInProgress = true;
    this.qrResultString = result;
    this.messages = [];
  }

  resetQrFields() {
    this.codeInProgress = false;
    this.qrResultString = '';
  }

  next() {
    this.palletLabelService.validatePalletCode(this.warehouse, this.accountId, this.qrResultString).subscribe(isValid => {
      if (isValid) {
        this.showPalletForm = true;
      }
      //this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Se ha actualizado el perfil.', life: 5000 });
    }, error => {
      this.codeInProgress = false;
      this.messages = [
        { severity: 'error', detail: error.error.message }
      ];
    });
  }

  openCamera() {
    const cameraInput = document.getElementById('cameraInput') as HTMLInputElement;
    cameraInput.click();
  }

  onPhotoSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage() {
    this.selectedImage = null;
  }

  onFinishInventory() {
    this.submitted = true;
    if (this.palletForm.valid) {
      this.palletData = { ...this.palletForm.getRawValue(), qrCode: this.qrResultString }
      this.onFinishItemScan();
    }
  }

  onFinishItemScan() {    
    this.onFinish.emit(this.palletData);
  }

}
