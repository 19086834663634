<div>
    <div *ngIf="!showPalletForm">
        <div class="field">
            <p-dropdown appendTo="body" [(ngModel)]="selectedDevice" [options]="availableDevices"
                placeholder="Seleccione una camara"></p-dropdown>
        </div>
        <div class="field">
            <label for="qr">Escanea tu código QR de pallet <span class="req">*</span></label>
            <zxing-scanner id="qr" [(device)]="selectedDevice" (camerasFound)="onCamerasFound($event)"
                (scanSuccess)="handleQrCodeResult($event)" [start]="true">
            </zxing-scanner>
        </div>
        <div *ngIf="qrResultString">
            <div class="field">
                <p-chip [label]="'Código Pallet: '+qrResultString" icon="pi pi-code" (onRemove)="resetQrFields()"
                    [removable]="true" />
            </div>
            <div class="field">
                <p-messages [(value)]="messages" [enableService]="false" [closable]="false" />
            </div>
            <div class="field">
                <p-button size="large" icon="pi pi-save" label="Siguiente" (onClick)="next()" />
            </div>
        </div>
    </div>

    <form [formGroup]="palletForm">
        <div *ngIf="showPalletForm">
            <div class="field">
                <label for="pallet_length">Largo <span class="req">*</span></label>
                <p-inputNumber formControlName="depth" id="pallet_length" inputId="minmax" mode="decimal" [min]="1"
                    [max]="5000" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros" required>
                </p-inputNumber>
                <small class="p-error"
                    *ngIf="submitted && (formControl['depth'].errors?.['required'] || formControl['depth'].errors?.['whitespace'] || formControl['depth'].errors?.['minlength'])">Largo
                    es requerido.</small>
            </div>

            <div class="field">
                <label for="pallet_width">Ancho <span class="req">*</span></label>
                <p-inputNumber formControlName="width" id="pallet_width" inputId="minmax" mode="decimal" [min]="1"
                    [max]="5000" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros" required>
                </p-inputNumber>
                <small class="p-error"
                    *ngIf="submitted && (formControl['width'].errors?.['required'] || formControl['width'].errors?.['whitespace'] || formControl['width'].errors?.['minlength'])">Ancho
                    es requerido.</small>
            </div>

            <div class="field">
                <label for="pallet_height">Alto <span class="req">*</span></label>
                <p-inputNumber formControlName="height" id="pallet_height" inputId="minmax" mode="decimal" [min]="1"
                    [max]="5000" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros" required>
                </p-inputNumber>
                <small class="p-error"
                    *ngIf="submitted && (formControl['height'].errors?.['required'] || formControl['height'].errors?.['whitespace'] || formControl['height'].errors?.['minlength'])">Alto
                    es requerido.</small>
            </div>

            <div class="field">
                <label for="adress_notes">Notas</label>
                <textarea formControlName="notes" id="adress_notes" rows="3" cols="30" pInputTextarea
                    class="p-inputtext-sm w-full" maxlength="200"></textarea>
            </div>

            <div class="field">
                <p-button (click)="openCamera()" label="Tomar Foto" icon="pi pi-camera" [rounded]="true" severity="info"
                    [outlined]="true" />
                <input formControlName="file" type="file" id="cameraInput" accept="image/*" capture="environment"
                    (change)="onPhotoSelected($event)" style="display:none;">
            </div>

            <div class="field">
                <p-chip *ngIf="selectedImage" label="Foto seleccionada" removable="true" (onRemove)="removeImage()">
                    <img [src]="selectedImage" alt="Foto" style="width: 50px; height: 50px; margin-left: 10px;" />
                </p-chip>
            </div>

            <div class="field">
                <label for="flooring">Nivel</label>
                <p-dropdown formControlName="flooring" id="locations" appendTo="body" [(ngModel)]="location"
                    [options]="locations" placeholder="Nivel"></p-dropdown>
            </div>
            <div class="field">
                <label for="location">Locación</label>
                <input formControlName="location" type="text" pInputText id="location" class="p-inputtext-sm w-full"
                    placeholder="Locación" />
            </div>

            <div class="field">
                <p-button size="large" icon="pi pi-save" label="Guardar Inventario" (onClick)="onFinishInventory()" />
            </div>
        </div>
    </form>
</div>