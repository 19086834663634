import { Warehouse } from './../../domain/warehouse.model';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ItemService } from '../../services/item.service';
import { Item, ItemSearchParams } from '../../domain/inventory.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountService } from '../../services/account.service';
import { PageableAccount } from '../../domain/account.model';
import { debounceTime, Subject } from 'rxjs';

@Component({
  selector: 'app-articles-inventory',
  templateUrl: './articles-inventory.component.html',
  styleUrl: './articles-inventory.component.scss'
})
export class ArticlesInventoryComponent {
  items: Item[] = [];
  accounts: any[] = [];
  totalRecords: number = 0;
  loading: boolean = true;
  filters: any = {};
  statuses = [
    { label: 'RECIBIDO', value: 'RECIBIDO' },
    { label: 'PALETIZADO', value: 'PALETIZADO' },
    { label: 'COMPLETADO', value: 'COMPLETADO' },
  ];
  warehouses: Warehouse[] = [
    {
      id: 1,
      name: 'Bodega Zapopan'
    }
  ];

  //Account dropdown
  lastQuery: string = '';
  searchAccountSubject = new Subject<string>();
  searchAccountQuery: string = '';

  //Search form
  searchForm: FormGroup = this.fb.group({
    warehouse: [''],
    accountId: [null],
    code: [null],
    description: [null],
    palletCode: [null],
    status: [null],
    creationDateFrom: [null],
    creationDateTo: [null],
  });
  tableRows: number = 30;

  constructor(private itemService: ItemService, private fb: FormBuilder, private accountService: AccountService) { }

  ngOnInit(): void {
    this.searchForm.patchValue({ warehouse: this.warehouses[0].name });
    this.fetchAccounts();
    this.searchAccountSubject.pipe(debounceTime(800)).subscribe(searchText => {
      this.searchAccountQuery = searchText;
      this.fetchAccounts();
    });
  }

  search() {
    this.loadItems({ first: 0, rows: this.tableRows });
  }

  loadAccounts(event: any) {
    this.searchAccountSubject.next(event.filter || '');
  }

  fetchAccounts() {
    this.loading = true;
    this.accountService.searchAllAccounts(this.searchAccountQuery).subscribe((response: PageableAccount) => {
      this.accounts = response.content;
      this.loading = false;
    });
  }

  loadItems(event: any) {
    this.loading = true;
    const params: ItemSearchParams = {
      ...this.searchForm.value,
      creationDateFrom: this.formatDate(this.searchForm.value.creationDateFrom),
      creationDateTo: this.formatDate(this.searchForm.value.creationDateTo),
      page: event.first / event.rows,
      size: event.rows,
      sortBy: 'creationDate',
      sortDirection: 'DESC',
      warehouse: this.searchForm.get('warehouse')?.value
    };

    this.itemService.searchItems(params).subscribe((data) => {
      this.items = data.content;
      this.totalRecords = data.totalElements;
      this.loading = false;
    });
  }

  private formatDate(date: Date | null): string | null {
    return date ? date.toISOString() : null;
  }

  getSeverity(status: string) {
    switch (status) {
      case 'COMPLETADO':
        return 'success';
      case 'PALETIZADO':
        return 'info';
      case 'RECIBIDO':
        return 'warning';
      default:
        return '';
    }
  }

  resetForm() {
    this.searchForm.reset();
    this.searchForm.patchValue({ warehouse: this.warehouses[0].name });
    //this.loadItems();
    //this.items = [];
  }

}
