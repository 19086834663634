import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PageableItems, InventoryCreation } from '../domain/inventory.model';
import { Dropdown } from '../domain/dropdown.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private apiUrl = environment.apiUrl + '/api/v1/inventory';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  getItemsByWarehouseAndCompany(warehouse: string, accountId: number, page: number, size: number): Observable<PageableItems> {
    const params = new HttpParams()
      .set('warehouse', warehouse)
      .set('accountId', accountId)
      .set('page', page)
      .set('size', size);
    return this.http.get<PageableItems>(`${this.apiUrl}`, { params });
  }

  searchItemsByDescription(warehouse: string, accountId: number, page: number, size: number, description: string): Observable<PageableItems> {
    const params = new HttpParams()
      .set('warehouse', warehouse)
      .set('accountId', accountId)
      .set('page', page)
      .set('size', size)
      .set('description', description);
    return this.http.get<PageableItems>(`${this.apiUrl}/search`, { params });
  }

  getInventoryTypes(): Dropdown[] {
    return [
      {
        id: 1,
        name: 'Ingreso por artículo'
      },
      {
        id: 2,
        name: 'Ingreso por cantidades'
      }
    ];
  }

  getPalletLocation(): string[] {
    return ["Piso", "Rack"];
  }

  create(inventoryCreation: InventoryCreation): Observable<InventoryCreation> {
    return this.http.post<InventoryCreation>(this.apiUrl, inventoryCreation, this.httpOptions);
  }
}
