import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, finalize, catchError } from 'rxjs/operators';
import { LoadingService } from '../../services/loading.service';
import { MessageService } from 'primeng/api';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private activeRequests = 0

  constructor(private loadingService: LoadingService, private messageService: MessageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.activeRequests++;
    this.loadingService.showLoading();
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401 && error.status !== 403 && error.status !== 400) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error.statusText, life: 3000 });
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.loadingService.hideLoading();
        }
      })
    );
  }
}
