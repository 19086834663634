import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NewItem, Constants } from '../../domain/inventory.model';
import { SimpleLabelService } from '../../services/simple-label.service';

@Component({
  selector: 'app-qr-scanner',
  standalone: false,
  templateUrl: './qr-scanner.component.html',
  styleUrl: './qr-scanner.component.scss',
})
export class QrScannerComponent implements OnInit {
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined = undefined;
  deviceIndex = 1;
  hasDevices: boolean = false;
  qrResultString: string = '';
  serialNumber: string = '';
  messages: Message[] = [];
  private qrSearchSubject = new Subject<string>();
  codeInProgress: boolean = false;
  quantity: number = 1;
  @Input() type: number = 1;
  @Input() description: string = '';
  @Input() scannedItems: NewItem[] = [];
  @Output() onFinish = new EventEmitter<NewItem[]>();
  constants = Constants;

  constructor(private scanner: ZXingScannerComponent, private simpleLabelService: SimpleLabelService) {
    this.qrSearchSubject.pipe(
      debounceTime(500)
    ).subscribe(result => {
      if (!this.isScanned(result)) {
        this.playSound();
        this.codeInProgress = true;
        this.qrResultString = result;
        this.messages = [];
      } else {
        this.messages = [
          { severity: 'info', detail: 'Este código QR ya ha sido escaneado.' }
        ];
      }
    });
  }

  playSound() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/beepSound2.mp3';
    audio.load();
    audio.play();
  }

  ngOnInit() {
    this.resetQrFields();
    this.scanner.askForPermission();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.selectedDevice = this.availableDevices[0];
    this.hasDevices = Boolean(devices && devices.length);
  }

  stopQrScan() {
  }

  handleQrCodeResult(result: string) {
    if (this.codeInProgress) { return; }
    this.qrSearchSubject.next(result);
  }

  readNextCode() {
    this.pushItem();
    this.resetQrFields();
  }

  isScanned(code: string): boolean {
    return this.scannedItems.some(item => item.code === code);
  }

  onFinishItemScan() {
    this.onFinish.emit(this.scannedItems);
  }

  onFinishQuantityScan() {
    this.pushItem();
    this.onFinish.emit(this.scannedItems);
  }

  pushItem() {
    this.scannedItems.push(
      {
        code: this.qrResultString,
        serialNumber: this.serialNumber,
        description: this.description.toUpperCase(),
        quantity: this.quantity,
        type: this.type
      });
  }

  resetQrFields() {
    this.codeInProgress = false;
    this.qrResultString = '';
    this.serialNumber = '';
  }

}
