import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PalletLabel } from '../domain/pallet_label.model'; 
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PalletLabelService {
  private apiUrl = environment.apiUrl + '/api/v1/pallet/labels';

  constructor(private http: HttpClient) { }

  getAll(): Observable<PalletLabel[]> {
    return this.http.get<PalletLabel[]>(this.apiUrl);
  }

  incrementCounter(id: number, incrementValue: number): Observable<void> {
    const params = new HttpParams().set('incrementValue', incrementValue.toString());
    return this.http.post<void>(`${this.apiUrl}/${id}/increment`, null, { params });
  }

  printLabels(id: number, from: number, to: number): Observable<ArrayBuffer> {
    const params = { from: from.toString(), to: to.toString() };
    const headers = new HttpHeaders({ 'Accept': 'application/pdf' });
    return this.http.get<ArrayBuffer>(`${this.apiUrl}/${id}/print`, { params, headers, responseType: 'arraybuffer' as 'json' });
  }

  getByAccountId(accountId: number): Observable<PalletLabel> {
    return this.http.get<PalletLabel>(`${this.apiUrl}/${accountId}`, {});
  }

  validatePalletCode(warehouse: string, accountId: number, code: string): Observable<boolean> {
    const params = new HttpParams()
      .set('code', code);
    return this.http.get<boolean>(`${this.apiUrl}/${warehouse}/${accountId}/validate`, { params });
  }

}
