import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthService } from '../../../services/auth.service';
import { User } from '../../../domain/user.model';
import { RoleConstants } from '../../../domain/role.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  title = 'bild-ui';
  menuItems: MenuItem[] | undefined;
  profileItems: MenuItem[] | undefined;
  userProfileVisible: boolean = false;
  userDetails: User = {};

  constructor(private messageService: MessageService, private authService: AuthService) {}

  ngOnInit() {
    this.loadUserDetails();
    const userRole: string = this.authService.getUserRole() || '';

    this.menuItems = [
      {
        label: 'Administración',
        icon: 'pi pi-fw pi-wrench',        
        items: [
          {
            label: 'Etiquetas',
            icon: 'pi pi-fw pi-tags',
            visible: !this.authService.canAccess([RoleConstants.BILD_VENTAS]),
            items: [
              {
                label: 'Etiqueta simple',
                icon: 'pi pi-fw pi-tag',
                visible: this.authService.canAccess([RoleConstants.BILD_ADMIN, RoleConstants.BILD_ENCARGADO]),
                routerLink: '/home/simpleLabels'
              },
              {
                label: 'Etiqueta pallet',
                icon: 'pi pi-fw pi-tag',
                visible: this.authService.canAccess([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO]),
                routerLink: '/home/palletLabels'
              }
            ]
          },
          {
            label: 'Usuarios',
            icon: 'pi pi-fw pi-users',
            visible: this.authService.canAccess([RoleConstants.BILD_ADMIN, RoleConstants.BILD_ENCARGADO]),
            routerLink: '/home/users',
          },
          {
            label: 'Cuentas',
            icon: 'pi pi-fw pi-sitemap',
            visible: this.authService.canAccess([RoleConstants.BILD_ADMIN, RoleConstants.BILD_OPERADOR, RoleConstants.BILD_ENCARGADO, RoleConstants.BILD_VENTAS]),
            routerLink: '/home/accounts'
          }
        ]
      },
      {
        label: 'Ordenes',
        icon: 'pi pi-fw pi-file-edit',
        visible: false,
        routerLink: '/home/orders'
      },
      {
        label: 'Inventario',
        icon: 'pi pi-fw pi-list',
        visible: this.authService.canAccess([RoleConstants.BILD_ADMIN]),
        routerLink: '/home/inventory'
      },
      {
        label: 'Ayuda',
        icon: 'pi pi-fw pi-question-circle',
        command: () => this.openEmailSender()
      }
    ];

    this.profileItems = [
      {
        label: 'Perfil',
        icon: 'pi pi-fw pi-user',
        items: [
          {
            label: 'Editar perfil',
            icon: 'pi pi-fw pi-cog',
            command: () => {
              this.userProfileVisible = true;
            }
          },
          {
            label: 'Salir',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
              this.userDetails = {};
              this.authService.logout();
            }
          }
        ]
      }
    ];
  }

  openEmailSender() {
    window.location.href = 'mailto:victor.lopez@bild.com.mx?subject=Ayuda%20con%20el%20sistema%20de%20Bild&body=Describe%20tu%20problema%20aquí.';
  }

  private loadUserDetails() {
    const user = this.authService.getUserDetails();
    this.userDetails = user;
  }

  resetUserProfileVisibility() {
    this.userProfileVisible = false;
  }
}
