import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {}

  onLogin(): void {
    this.messageService.clear();
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        (response: any) => {
          this.authService.setToken(response.jwt);
          this.authService.setUserDetails(response.user);
          this.router.navigate(['/home/inventory/articles']);
        },
        (error) => {
          this.messageService.add({severity: 'error', summary: 'Invalid User or Password'});
        }
      );
    }
  }

  onInputChange() {
    this.messageService.clear();
  }
}
