<div class="login-container">
  <div class="login-form">
    <div class="image-div">
      <img src="./assets/b-logo-dark.svg" height="40" class="login-logo" />
    </div>
    <h2>Iniciar Sesión</h2>
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
      <div class="form-group">
        <label for="username">Username</label>
        <input id="username" type="text" formControlName="username" (input)="onInputChange()" required>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input id="password" type="password" formControlName="password" (input)="onInputChange()" required>
      </div>
      <button type="submit" label="Login">Login</button>
    </form>
    <p-messages [closable]="false" />
  </div>
</div>