import { Component } from '@angular/core';
import { PalletLabel } from '../../../domain/pallet_label.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AbstractControl, ValidatorFn, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '../../../services/account.service';
import { PalletLabelService } from '../../../services/pallet-label.service';
import { AccountDropdown } from '../../../domain/accountDropdown.model';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-pallet-labels',
  standalone: false,
  templateUrl: './pallet-labels.component.html',
  styleUrl: './pallet-labels.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class PalletLabelsComponent {
  generateLabelsNumber: number = 1;
  labelDialog: boolean = false;
  printDialog: boolean = false;
  printLabelsFrom: number = 1;
  printLabelsTo: number = 1;
  labelsCounter: number = 0;
  printLabelSubmitted:boolean = false;
  selectedAccount: AccountDropdown | undefined;
  selectedPalletLabel: PalletLabel = {};
  isAdmin: boolean = false;
  printLabelForm: FormGroup = this.formBuilder.group({
    printLabelsFrom: ['', Validators.required],
    printLabelsTo: ['', Validators.required],
  }, { validator: this.compareValuesValidator() });
  
  account_array: AccountDropdown[] = [];
  pallet_labels_array: PalletLabel[] = [];

  constructor(
    private messageService: MessageService, 
    private confirmationService: ConfirmationService, 
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private palletLabelService: PalletLabelService,
    private authService: AuthService) {}

  ngOnInit(): void { 
    this.getAllAccounts();
    this.getAllPalletLabels();
  }

  getAllAccounts() {
    this.accountService.getAllDropdownAccounts().subscribe(
      (data: AccountDropdown[]) => {
        this.account_array = data;
      }
    );
  }

  getAllPalletLabels() {
    this.palletLabelService.getAll().subscribe(
      (data: PalletLabel[]) => {
        this.pallet_labels_array = data;
      }
    );
  }

  getPalletLabel(accountId: number) {
    if (accountId == 0) return;
    this.palletLabelService.getByAccountId(accountId).subscribe(
      (data: PalletLabel) => {
        if (data) {
          this.labelsCounter = data.counter || 0;
          this.selectedPalletLabel = data;
        } else {
          this.labelsCounter = 0;
        }
        this.printLabelsTo = this.labelsCounter;
        this.printLabelsFrom = this.labelsCounter;
        
      }
    );
  }

  setLabelRanges() {
    this.labelsCounter = (this.selectedPalletLabel.counter || 0) + this.generateLabelsNumber;
    this.printLabelsFrom = (this.labelsCounter - this.generateLabelsNumber) + 1;
    this.printLabelsTo = this.labelsCounter;
  }

  get formControl(): any {
    return this.printLabelForm.controls;
  }

  onCreateNewLabels() {
    this.generateLabelsNumber = 1;
    this.setLabelRanges();
    this.labelDialog = true;
  }

  onChangeAccount() {
    this.getPalletLabel(this.selectedAccount?.accountId || 0);
  }

  onLabelQuantityChange(): void {
    this.setLabelRanges();
    console.log("Testing changes quanityt!!!");
  }
  
  generateNewLabels() {
    this.confirmationService.confirm({
      header: 'Generar Etiquetas',
      message: 'Esta seguro que desea crear ' + this.generateLabelsNumber + ' etiquetas?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-sm',
      acceptButtonStyleClass: 'p-button-outlined p-button-sm',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      accept: () => {
        this.palletLabelService.incrementCounter(this.selectedAccount?.accountId || -1, this.generateLabelsNumber).subscribe(
          () => {
            this.hideNewLabelDialog();
            this.printDialog = true;
            this.selectedPalletLabel.counter = (this.selectedPalletLabel.counter || 0 ) + this.generateLabelsNumber;
            this.getAllPalletLabels();
            this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Etiquetas creadas', life: 3000 });
          }
        );
        
      }
    });
  }

  hideNewLabelDialog() {
    this.labelDialog = false;
  }

  onPrintLabels() {
    this.labelsCounter = this.selectedPalletLabel.counter || 0;
    this.printLabelsFrom = this.selectedPalletLabel.counter || 0 ;
    this.printLabelsTo = this.selectedPalletLabel.counter || 0;
    this.printDialog = true;
  }

  get calculatePrintRange(): { [key: string]: boolean } {
    if (this.printLabelsFrom > this.printLabelsTo) {
      //From greater than To
      return { 'invalidRange': true };
    }
    if ( ((this.printLabelsTo+1) - this.printLabelsFrom) > 100) {
      return { 'maxExceeded': true };;
    }
  return { 'invalidRange': false };;
  }

  hidePrintLabelsDialog() {
    this.printDialog = false;
  }

  printLabels() {
    this.printLabelSubmitted = true;
    if (this.printLabelForm.valid){
      this.palletLabelService.printLabels(this.selectedAccount?.accountId || -1, this.printLabelsFrom, this. printLabelsTo).subscribe(
        (response: ArrayBuffer) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        (error) => {
          console.error('Error generating pallet labels', error);
        }
      );
    }
    
  }

  compareValuesValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const from = control.get('printLabelsFrom');
      const to = control.get('printLabelsTo');
  
      if (from && to && from.value > to.value) {
        return { 'invalidRange': true };
      }
      if (((to?.value+1) - from?.value) > 100) {
        return { 'maxExceeded': true };
      }
      return null;
    };
  }
}
