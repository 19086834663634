<div class="pallet-container p-grid p-fluid">
    <p-panel>
        <ng-template pTemplate="header">
            <div class="custom-header">
                <span class="header-title">Escaneo de Pallet</span>
                <button pButton icon="pi pi-times" class="p-button-rounded p-button-text" (click)="onClose()"></button>
            </div>
        </ng-template>

        <div class="content">
            <!-- View Messages -->
            <p-toast [showTransformOptions]="'translateX(-100%)'" [showTransitionOptions]="'1000ms'"
                [hideTransitionOptions]="'500ms'" [showTransformOptions]="'translateY(100%)'"
                [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }" />

            <!-- QR Scanner -->
            <div class="p-col-12 mb-2" *ngIf="!qrCode">
                <h6>Escanear Etiqueta Pallet</h6>
                <p-menu #menu [model]="cameraMenuItems" [popup]="true" />
                <zxing-scanner (click)="menu.toggle($event)" (tap)="menu.toggle($event)" [device]="selectedDevice" (scanSuccess)="onScanSuccess($event)"></zxing-scanner>
            </div>


            <div class="p-col-12 mb-2" *ngIf="qrCode">
                <p-chip [label]="qrCode" icon="" (onRemove)="resetQr()"
                    [removable]="true"><strong>Código:</strong></p-chip>
            </div>

            <form [formGroup]="palletForm">
                <div class="field">
                    <label for="pallet_length">Largo <span class="req">*</span></label>
                    <p-inputNumber formControlName="depth" id="pallet_length" inputId="minmax" mode="decimal" [min]="1"
                        [max]="5000" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros" required>
                    </p-inputNumber>
                    <small class="p-error"
                        *ngIf="submitted && (formControl['depth'].errors?.['required'] || formControl['depth'].errors?.['whitespace'] || formControl['depth'].errors?.['minlength'])">Largo
                        es requerido.</small>
                </div>

                <div class="field">
                    <label for="pallet_width">Ancho <span class="req">*</span></label>
                    <p-inputNumber formControlName="width" id="pallet_width" inputId="minmax" mode="decimal" [min]="1"
                        [max]="5000" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros" required>
                    </p-inputNumber>
                    <small class="p-error"
                        *ngIf="submitted && (formControl['width'].errors?.['required'] || formControl['width'].errors?.['whitespace'] || formControl['width'].errors?.['minlength'])">Ancho
                        es requerido.</small>
                </div>

                <div class="field">
                    <label for="pallet_height">Alto <span class="req">*</span></label>
                    <p-inputNumber formControlName="height" id="pallet_height" inputId="minmax" mode="decimal" [min]="1"
                        [max]="5000" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros" required>
                    </p-inputNumber>
                    <small class="p-error"
                        *ngIf="submitted && (formControl['height'].errors?.['required'] || formControl['height'].errors?.['whitespace'] || formControl['height'].errors?.['minlength'])">Alto
                        es requerido.</small>
                </div>

                <div class="field">
                    <label for="adress_notes">Notas</label>
                    <textarea formControlName="notes" id="adress_notes" rows="3" cols="30" pInputTextarea
                        class="p-inputtext-sm w-full" maxlength="200"></textarea>
                </div>

                <div class="field">
                    <p-button (click)="openCamera()" label="Tomar Foto" icon="pi pi-camera" [rounded]="true"
                        severity="info" [outlined]="true" />
                    <input type="file" id="cameraInput" accept="image/*" capture="environment"
                        (change)="onPhotoSelected($event)" style="display:none;" multiple>
                </div>

                <div class="field">
                    <ng-container *ngIf="selectedImages.length > 0">
                        <p-chip *ngFor="let image of selectedImages; let i = index" [label]="'Foto ' + (i+1)"
                            removable="true" (onRemove)="removeImage(i)" class="p-chip mb-1 ml-1"
                            styleClass="padding: 0 0.50rem;">
                            <img [src]="image" alt="Foto" style="width: 30px; height: 30px; margin-left: 2px;" />
                        </p-chip>
                    </ng-container>
                </div>

                <div class="field">
                    <label for="flooring">Nivel</label>
                    <p-dropdown formControlName="flooring" id="flooring" appendTo="body" [(ngModel)]="flooring"
                        [options]="floorings" placeholder="Nivel"></p-dropdown>
                </div>
                <div class="field">
                    <label for="location">Locación</label>
                    <input formControlName="location" type="text" pInputText id="location" class="p-inputtext-sm w-full"
                        placeholder="Locación" />
                </div>

                <div class="field">
                    <p-button label="Guardar Pallet" icon="pi pi-save" (click)="onSave()"
                        [disabled]="!qrCode"></p-button>
                </div>

            </form>
        </div>
    </p-panel>
</div>
<p-confirmDialog></p-confirmDialog>