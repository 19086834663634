<h2>Cuentas</h2>
<!--Table-->
<div class="card">
    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            <button pButton pRipple label="Nueva" icon="pi pi-plus" class="p-button-primary mr-2"
                (click)="openNew()" *ngIf="hasActionAccess"></button>
            <button pButton pRipple label="Eliminar" icon="pi pi-trash" class="p-button-danger"
                (click)="deleteSelectedAccounts()" [disabled]="!selectedAccounts || !selectedAccounts.length" *ngIf="hasActionAccess"></button>
        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label="Exportar" icon="pi pi-upload" class="p-button-primary"
                (click)="exportToExcel()"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="accounts" [rows]="20" [paginator]="true"
        [globalFilterFields]="['name', 'country.name', 'representative.name', 'status']"
        [tableStyle]="{ 'min-width': '75rem' }" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" [(selection)]="selectedAccounts"
        [rowHover]="true" dataKey="accountId" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <h5 class="m-0">Administrar Cuentas</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt.filter($any($event.target)?.value, 'email', 'contains')"
                        [value]="$any(dt.filters['email'])?.value" placeholder="Buscar por correo"
                        class="p-column-filter">
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="name" style="min-width:15rem">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="email" style="min-width:15rem">Email <p-sortIcon field="email"></p-sortIcon></th>
                <th pSortableColumn="phone">Telefono <p-sortIcon field="phone"></p-sortIcon></th>
                <th pSortableColumn="businessName" style="min-width:10rem">Razon Social <p-sortIcon
                        field="businessName"></p-sortIcon></th>
                <th pSortableColumn="creationTime">Fecha de creación <p-sortIcon field="phone"></p-sortIcon></th>        
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-account>
            <tr>
                <td>
                    <p-tableCheckbox [value]="account"></p-tableCheckbox>
                </td>
                <td>{{ account.name }}</td>
                <td>{{ account.email }}</td>
                <td>{{ account.phone }}</td>
                <td>{{ account.businessName }}</td>
                <td>{{ account.creationTime | date:'medium'}}</td>
                <td>
                    <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                        (click)="viewAccount(account)"></button>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2"
                        (click)="editAccount(account)" *ngIf="hasActionAccess" ></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                        (click)="deleteAccount(account)" *ngIf="hasActionAccess"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">En total hay {{ accounts ? accounts.length : 0
                }} cuentas.</div>
        </ng-template>
    </p-table>
</div>

<!-- Dialog -->
<p-dialog [(visible)]="accountDialog" [style]="{ width: '92%' }" header="Detalles de Cuenta" [modal]="true"
    styleClass="p-fluid" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
        <form [formGroup]="accountForm">

            <h4>Datos administrativos</h4>

            <div class="formgrid grid">
                <div class="field col-12 md:col-3 sm:col-4">
                    <label for="name">Nombre <span class="req">*</span></label>
                    <input formControlName="name" type="text" pInputText id="name" [(ngModel)]="account.name" required
                        autofocus [readonly]="readOnly" allowEmpty="false" class="p-inputtext-sm" />
                    <small class="p-error"
                        *ngIf="submitted && (formControl['name'].errors?.['required'] || formControl['name'].errors?.['whitespace'] || formControl['name'].errors?.['minlength'])">Nombre
                        es requerido.</small>
                </div>

                <div class="field col-12 md:col-3 sm:col-4">
                    <label for="email">Correo <span class="req">*</span></label>
                    <input formControlName="email" type="email" pInputText id="email" [(ngModel)]="account.email"
                        required autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                    <small class="p-error" *ngIf="submitted && formControl['email'].errors?.['required']">Correo es
                        requerido.</small>
                    <small class="p-error" *ngIf="submitted && formControl['email'].errors?.['email']">Formato de correo
                        no
                        valido.</small>
                </div>

                <div class="field col-12 md:col-3 sm:col-4">
                    <label for="email">Teléfono <span class="req">*</span></label>
                    <input formControlName="phone" type="text" pInputText id="phone" [(ngModel)]="account.phone"
                        required autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                    <small class="p-error" *ngIf="submitted && formControl['phone'].errors?.['required']">Teléfono es
                        requerido.</small>
                    <small class="p-error" *ngIf="submitted && formControl['phone'].errors?.['pattern']">Formato de
                        Teléfono
                        no valido (10 Dígitos).</small>
                </div>

                <div class="field col-12 md:col-3 sm:col-4">
                    <label for="business_name">Razón social <span class="req">*</span></label>
                    <input formControlName="business_name" type="text" pInputText id="business_name"
                        [(ngModel)]="account.businessName" required autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                    <small class="p-error"
                        *ngIf="submitted && (formControl['business_name'].errors?.['required'] || formControl['business_name'].errors?.['whitespace'] || formControl['business_name'].errors?.['minlength'])">Razón
                        social es requerido.</small>
                </div>


                <div class="field col-12 sm:col-8">
                    <label for="observation">Observaciones</label>
                    <textarea formControlName="observation" type="text" rows="3" cols="30" pInputTextarea
                        id="observation" [(ngModel)]="account.observation" autofocus [readonly]="readOnly"
                        maxlength="200" style="resize: none;" class="p-inputtext-sm"></textarea>
                    <p *ngIf="!readOnly" class="font-bold text-xs text-right">{{ 200 - (account.observation?.length || 0) }}</p>
                </div>

            </div>

            <h4>Datos de Servicio</h4>

            <div class="formgrid grid">
                <div class="field col-12 md:col-3">
                    <label for="service_type">Tipo de servicio <span class="req">*</span></label>
                    <p-dropdown formControlName="service_type" id="service_type" appendTo="body"
                        [options]="serviceTypes" optionLabel="name" optionValue="name" [showClear]="true"
                        placeholder="Selecciona un tipo" [(ngModel)]="account.serviceType" autofocus required
                        [readonly]="readOnly" class="p-inputtext-sm"></p-dropdown>
                    <small class="p-error"
                        *ngIf="submitted && (formControl['service_type'].errors?.['required'] || formControl['service_type'].errors?.['whitespace'] || formControl['service_type'].errors?.['minlength'])">Tipo
                        de servicio es requerido.</small>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="product_types">Tipo de productos</label>
                    <input formControlName="product_types" type="text" pInputText id="product_types"
                        [(ngModel)]="account.productTypes" autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-4">
                    <label for="order_frequency">Frecuencia de pedidos</label>
                    <input formControlName="order_frequency" type="text" pInputText id="order_frequency"
                        [(ngModel)]="account.orderFrequency" autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-2">
                    <label for="skus_quantity">Cantidad de SKU's</label>
                    <p-inputNumber formControlName="skus_quantity" id="skus_quantity"
                        [(ngModel)]="account.skusQuantity" inputId="minmax" mode="decimal" [min]="1" [max]="3000"
                        autofocus [readonly]="readOnly" class="p-inputtext-sm" [showButtons]="true">
                    </p-inputNumber>
                </div>

                <div class="field col-12 md:col-2">
                    <label for="pallets_quantity">Cantidad de tarimas</label>
                    <p-inputNumber formControlName="pallets_quantity" id="pallets_quantity"
                        [(ngModel)]="account.palletsQuantity" inputId="minmax" mode="decimal" [min]="1" [max]="1000"
                        autofocus [readonly]="readOnly" class="p-inputtext-sm" [showButtons]="true"> </p-inputNumber>
                </div>

                <div class="field col-12 md:col-2">
                    <label for="pallet_weight">Peso de tarima</label>
                    <p-inputNumber formControlName="pallet_weight" id="pallet_weight"
                        [(ngModel)]="account.palletWeight" inputId="minmax" mode="decimal" [min]="1" [max]="5000"
                        autofocus [readonly]="readOnly" class="p-inputtext-sm" suffix=" Kg" placeholder="Kilos">
                    </p-inputNumber>
                </div>

                <div class="field col-12 md:col-1">
                    <label for="pallet_length">Largo</label>
                    <p-inputNumber formControlName="pallet_length" id="pallet_length"
                        [(ngModel)]="account.palletLength" inputId="minmax" mode="decimal" [min]="1" [max]="5000"
                        autofocus [readonly]="readOnly" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros">
                    </p-inputNumber>
                </div>

                <div class="field col-12 md:col-1">
                    <label for="pallet_width">Ancho</label>
                    <p-inputNumber formControlName="pallet_width" id="pallet_width" [(ngModel)]="account.palletWidth"
                        inputId="minmax" mode="decimal" [min]="1" [max]="5000" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" suffix=" cm" placeholder="centimetros"> </p-inputNumber>
                </div>

                <div class="field col-12 md:col-1">
                    <label for="pallet_height">Alto</label>
                    <p-inputNumber formControlName="pallet_height" id="pallet_height"
                        [(ngModel)]="account.palletHeight" inputId="minmax" mode="decimal" [min]="1" [max]="5000"
                        autofocus [readonly]="readOnly" class="p-inputtext-sm" suffix=" cm" placeholder="centimetros">
                    </p-inputNumber>
                </div>

                <div class="field col-12 md:col-2">
                    <label for="box_weight">Peso de caja</label>
                    <p-inputNumber formControlName="box_weight" id="box_weight" [(ngModel)]="account.boxWeight"
                        inputId="minmax" mode="decimal" [min]="1" [max]="5000" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" suffix=" Kg" placeholder="Kilos"> </p-inputNumber>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="reception_type">Tipo de recepción</label>
                    <p-dropdown formControlName="reception_type" id="reception_type" [options]="receptionTypes"
                        [(ngModel)]="account.receptionType" optionLabel="name" [showClear]="true"
                        placeholder="Seleccione un tipo" autofocus [readonly]="readOnly" optionValue="name"
                        appendTo="body" class="p-inputtext-sm"></p-dropdown>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="order_fulfillment">Preparado de pedidos</label>
                    <input formControlName="order_fulfillment" type="text" pInputText id="order_fulfillment"
                        [(ngModel)]="account.orderFulfillment" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-3">
                    <label for="packaging_type">Tipo de embalaje</label>
                    <input formControlName="packaging_type" type="text" pInputText id="packaging_type"
                        [(ngModel)]="account.packagingType" autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-3">
                    <label for="pallet_type">Tipo de paletizado</label>
                    <p-dropdown formControlName="pallet_type" id="pallet_type" [options]="palletTypes"
                        [(ngModel)]="account.palletType" [showClear]="true" placeholder="Seleccione un tipo" autofocus
                        [readonly]="readOnly" appendTo="body" class="p-inputtext-sm"></p-dropdown>
                </div>

                <div class="field col-12 md:col-3">
                    <label for="freight_unit">Tipo de flete</label>
                    <p-dropdown formControlName="freight_unit" id="freight_unit" [options]="receptionTypes"
                        [(ngModel)]="account.freightUnit" optionLabel="name" [showClear]="true"
                        placeholder="Seleccione un tipo" autofocus [readonly]="readOnly" optionValue="name"
                        appendTo="body" class="p-inputtext-sm"></p-dropdown>
                </div>

                <div class="field col-12 md:col-1">
                    <label for="freight_zip_code">C.P.</label>
                    <input formControlName="freight_zip_code" type="text" pInputText id="freight_zip_code"
                        [(ngModel)]="account.freightZipCode" autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-2">
                    <label for="freight_state">Estado</label>
                    <input formControlName="freight_state" type="text" pInputText id="freight_state"
                        [(ngModel)]="account.freightState" autofocus [readonly]="readOnly" class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-2">
                    <label for="freight_state">Delegación/Municipio</label>
                    <input formControlName="freight_municipality" type="text" pInputText id="freight_municipality"
                        [(ngModel)]="account.freightMunicipality" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-2">
                    <label for="freight_state">Colonia</label>
                    <input formControlName="freight_neighborhood" type="text" pInputText id="freight_neighborhood"
                        [(ngModel)]="account.freightNeighborhood" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-2">
                    <label for="freight_state">Calle</label>
                    <input formControlName="freight_street_name" type="text" pInputText id="freight_street_name"
                        [(ngModel)]="account.freightStreetName" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                </div>


                <div class="field col-12 md:col-1">
                    <label for="freight_exterior_number">Num Ext.</label>
                    <input formControlName="freight_exterior_number" type="text" pInputText id="freight_exterior_number"
                        [(ngModel)]="account.freightExteriorNumber" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                </div>

                <div class="field col-12 md:col-1">
                    <label for="freight_interior_number">Num Int.</label>
                    <input formControlName="freight_interior_number" type="text" pInputText id="freight_interior_number"
                        [(ngModel)]="account.freightInteriorNumber" autofocus [readonly]="readOnly"
                        class="p-inputtext-sm" />
                </div>

            </div>

        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-divider></p-divider>
        <!-- Sow when new or edit-->
        <div *ngIf="!readOnly">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text"
                (click)="saveAccount()"></button>
        </div>
        <!-- Sow when view-->
        <button *ngIf="readOnly" pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text"
            (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast></p-toast>