import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Constants, Item, Pallet, PalletCreation } from '../../domain/inventory.model';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { debounceTime, of, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../services/inventory.service';
import { ImageService } from '../../services/image.service';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-palletize',
  templateUrl: './palletize.component.html',
  styleUrl: './palletize.component.scss',
  providers: [ConfirmationService]
})
export class PalletizeComponent {
  palletCode: string = '';
  pallet: Pallet = {};
  scannedItems: Item[] = [];
  warehouse: string = '';
  accountId: number = 0;
  constants = Constants;
  paletize: boolean = false;
  private articleSearchSubject = new Subject<string>();

  // Camera settings
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined;
  cameraMenuItems: MenuItem[] = [];

  // Upload images
  selectedImages: string[] = [];
  imagesToUpload: File[] = [];

  constructor(private location: Location, private messageService: MessageService, private imageService: ImageService,
    private inventoryService: InventoryService, private route: ActivatedRoute, private router: Router, private confirmationService: ConfirmationService
  ) {
    this.warehouse = this.route.snapshot.paramMap.get('warehouse') || '';
    this.accountId = Number(this.route.snapshot.paramMap.get('accountId'));
    this.articleSearchSubject.pipe(
      debounceTime(1000)
    ).subscribe(result => {
      this.onArticleScanSuccess(result);
    });
  }

  ngOnInit(): void {
    this.listCameras();
    this.setHistoryState();
  }

  ngOnDestroy(): void {
    window.removeEventListener('popstate', this.onClose.bind(this));
  }

  private setHistoryState(): void {
    history.pushState(null, '');
    window.addEventListener('popstate', this.onClose.bind(this));
  }

  listCameras(): void {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.availableDevices = devices.filter(device => device.kind === 'videoinput');
      this.populateCameraMenu();
      if (this.availableDevices.length > 0) {
        this.selectedDevice = this.availableDevices[0];
      }
    });
  }

  populateCameraMenu(): void {
    this.cameraMenuItems = this.availableDevices.map((device, index) => ({
      label: device.label || `Cámara ${index + 1}`,
      command: () => this.selectCamera(device)
    }));
    this.cameraMenuItems = [
      {
        label: 'Cámaras',
        items: this.cameraMenuItems
      }
    ];
  }

  selectCamera(device: MediaDeviceInfo): void {
    this.selectedDevice = device;
  }

  openCamera() {
    const cameraInput = document.getElementById('cameraInput') as HTMLInputElement;
    cameraInput.click();
  }


  onPhotoSelected(event: any) {
    const files: FileList = event.target.files;
    if (files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageService.compressImage(file).then((compressedFile) => {
          this.selectedImages.push(e.target.result);
          this.imagesToUpload.push(compressedFile);
        });
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(index: number) {
    this.selectedImages.splice(index, 1);
    this.imagesToUpload.splice(index, 1);
  }

  onArticleScan(qrCode: string) {
    this.articleSearchSubject.next(qrCode);
  }

  onPalletScanSuccess(palletCode: string) {
    this.playSound();
    if (this.palletCode) {
      this.messageService.add({ severity: 'warn', summary: 'Código duplicado', detail: 'El pallet ya tiene un código asignado' });
      return;
    }

    this.inventoryService.getExistingPallet(this.warehouse, this.accountId, palletCode).subscribe((pallet: Pallet) => {
      if (pallet.palletId) {
        this.palletCode = palletCode;
        this.pallet = pallet;
        this.messageService.add({ severity: 'success', summary: 'Escaneado', detail: 'Código de Pallet agregado' });
      } else {
        this.router.navigate(['/pallet-scanner'], {
          queryParams: {
            warehouse: this.warehouse,
            accountId: this.accountId,
            palletCode: palletCode
          },
          state: { items: this.scannedItems }
        });
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  onArticleScanSuccess(qrCode: string) {
    this.playSound();
    if (this.scannedItems.some(item => item.code === qrCode)) {
      this.messageService.add({ severity: 'warn', summary: 'Código duplicado', detail: 'Este código ya esta en la lista' });
      return;
    }

    this.inventoryService.getExistingItem(this.warehouse, this.accountId, qrCode).subscribe((item: Item) => {
      if (item) {
        this.messageService.add({ severity: 'success', summary: 'Escaneado', detail: 'Código de Pallet agregado' });
        this.scannedItems.push(item);
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  playSound() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/beepSound2.mp3';
    audio.load();
    audio.play();
  }

  resetPalletCode() {
    this.palletCode = '';
    this.pallet = {};
  }

  removeItem(index: number) {
    this.scannedItems.splice(index, 1);
  }

  onClose() {
    this.confirmationService.confirm({
      message: '¿Quieres cancelar el Paletizado?',
      header: 'Confirmación',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        window.removeEventListener('popstate', this.onClose.bind(this));
        this.router.navigate(['/home/inventory/articles']);
        //this.location.back();
      },
      reject: () => {
        history.pushState(null, '');
      },
    });
  }

  onSave() {
    if (!this.pallet) {
      this.messageService.add({ severity: 'error', summary: 'Código requerido', detail: 'Escanea el código del Pallet antes de guardar' });
      return;
    }

    const payload: PalletCreation = {
      pallet: this.pallet,
      items: this.scannedItems
    };

    this.inventoryService.moveOrPalletizeItems(payload)
      .pipe(
        switchMap((response: number) => {
          const files: File[] = this.imagesToUpload || [];
          if (files.length > 0) {
            return this.imageService.uploadImages(files, this.pallet.palletId || '');
          }
          return of();
        })
      ).subscribe(() => {
        this.resetPalletCode();
        this.messageService.add({ severity: 'success', summary: 'Paletizado completado', detail: 'Los artículos fueron Paletizados', life: 5000 });
        setTimeout(() => {
          this.router.navigate(['/home/inventory']);
        }, 2000);
      }, resp => {
        console.error('Error creating the pallet', resp);
        this.messageService.add({ severity: 'error', summary: resp.error.message });
      });
  }

}
