<h2>Ordenes</h2>
<!--Table-->
<div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            <button pButton pRipple label="Crear Orden" icon="pi pi-plus" class="p-button-primary mr-2"
                (click)="openNew()"></button>
            <button pButton pRipple label="Eliminar" icon="pi pi-trash" class="p-button-danger"
                (click)="deleteSelectedOrder()" [disabled]="!selectedOrders || !selectedOrders.length"></button>
        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label="Exportar" icon="pi pi-upload" class="p-button-primary"
                (click)="exportToExcel()"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="orders" [rows]="20" [paginator]="true" [tableStyle]="{ 'min-width': '75rem' }"
        styleClass="p-datatable-sm" [(selection)]="selectedOrders" [rowHover]="true" dataKey="order_request_id"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <h5 class="m-0">Administrar Ordenes</h5>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text"
                        (input)="dt.filter($any($event.target)?.value, 'account_name', 'contains')"
                        [value]="$any(dt.filters['account_name'])?.value" placeholder="Buscar por cuenta"
                        class="p-column-filter">
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="delivery_number">Orden ID</th>
                <th pSortableColumn="account_name">Nombre de cuenta</th>
                <th pSortableColumn="creation_time">Fecha de creación <p-sortIcon field="creation_time"></p-sortIcon>
                </th>
                <th pSortableColumn="close_date">Fecha de cierre <p-sortIcon field="close_date"></p-sortIcon></th>
                <th pSortableColumn="updated_by">Atendida por</th>
                <th pSortableColumn="service_type">Tipo de orden</th>
                <th pSortableColumn="status">Estatus</th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
            <tr>
                <td>
                    <p-tableCheckbox [value]="order"></p-tableCheckbox>
                </td>
                <td>{{ order.delivery_number}}</td>
                <td>{{ order.account_name }}</td>
                <td>{{ order.creation_time }}</td>
                <td>{{ order.close_date }}</td>
                <td>{{ order.updated_by }}</td>
                <td>{{ order.service_type }}</td>
                <td>{{ order.status }}</td>
                <td>
                    <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                        (click)="test2(order)"></button>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2"
                        (click)="test2(order)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                        (click)="deleteOrder(order)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">En total hay {{ orders ? orders.length : 0
                }} ordenes.</div>
        </ng-template>
    </p-table>
</div>

<!-- Order creation Dialog -->
<p-dialog [(visible)]="orderDialog" [style]="{ width: '500px' }" header="Detalles de Orden" [modal]="true"
    styleClass="p-fluid" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
        
        
    </ng-template>

    <ng-template pTemplate="footer">
        <!-- Sow when new or edit-->
        <div *ngIf="!readOnly">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="test()"></button>
            <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text"
                (click)="test()"></button>
        </div>
        <!-- Sow when view-->
        <button *ngIf="readOnly" pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text"
            (click)="test()"></button>

    </ng-template>
</p-dialog>


<!-- confirm dialog-->
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>