<h2>Inventario</h2>
<!--Table-->
<div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4">
        <div class="grid col-12 py-0">
            <div class="col col-12 xl:col-3">
                <p-dropdown [options]="warehouses" [(ngModel)]="selectedWarehouse" placeholder="Selecciona una bodega"
                    optionLabel="name" [style]="{'width':'100%'}"></p-dropdown>
            </div>
            <div class="col col-12 xl:col-3">
                <p-dropdown [options]="account_array" [(ngModel)]="selectedAccount" placeholder="Selecciona una cuenta"
                    optionLabel="name" filter="true" showClear="true" filterPlaceholder="Buscar..."
                    (ngModelChange)="onChangeAccount()" [style]="{'width':'100%'}">
                </p-dropdown>
            </div>
            <div class="col col-12 xl:col-6 flex align-items-center justify-content-end">
                <p-button label="Agregar Artículos" icon="pi pi-plus" class="p-button-primary" (click)="onAddItems()"
                    [disabled]="!selectedAccount"></p-button>
            </div>
        </div>
    </p-toolbar>

    <div class="card">
        <p-table #dt [value]="items" rowGroupMode="rowspan" groupRowsBy="itemGroup.groupId" sortField="descripcion"
            sortMode="single" styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped" [tableStyle]="{'min-width': '75rem'}"
            [paginator]="true" [rows]="rows" [totalRecords]="totalRecords" [lazy]="true"
            (onLazyLoad)="loadItemsLazy($event)">
            <ng-template pTemplate="caption">
                <div class="grid col-12">
                    <div class="col col-12 xl:col-6">
                        <h5>Administrar Inventario de {{selectedAccount.name}} en {{
                            selectedWarehouse.name}}
                        </h5>
                    </div>
                    <div class="col col-12 xl:col-6 flex align-items-center justify-content-end">
                        <div class="formgroup-inline">
                            <div class="field">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text" [(ngModel)]="searchValue" (ngModelChange)="onSearch()"
                                        placeholder="Buscar por descripción" class="p-column-filter"
                                        [style]="{'width':'100%'}">
                                </span>
                            </div>
                            <p-button label="Limpiar" [outlined]="true" icon="pi pi-filter-slash"
                                (onClick)="clear(dt)" />
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:3rem">#</th>
                    <th>Descripción</th>
                    <th>Código de artículo</th>
                    <th>Cantidad</th>
                    <th>Número de Serie</th>
                    <th>Código de pallet</th>
                    <th>Usuario</th>
                    <th>Fecha de creación</th>
                    <th>Fecha de salida</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-inventory let-rowIndex="rowIndex" let-rowgroup="rowgroup"
                let-rowspan="rowspan">
                <tr>
                    <td>{{rowIndex}}</td>
                    <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                        <span class="font-bold ml-2">{{inventory.itemGroup.description}}</span>
                        <p *ngIf="rowspan" class="text-xs ml-2">Total: {{inventory.itemGroup.quantity}}</p>
                    </td>
                    <td>
                        {{inventory.item.code}}
                    </td>
                    <td>
                        {{inventory.quantity}}
                    </td>
                    <td>
                        {{inventory.item.serialNumber}}
                    </td>
                    <td>
                        {{inventory.item.palletCode}}
                    </td>
                    <td>
                        {{inventory.item.user}}
                    </td>
                    <td>
                        {{inventory.item.creationDate | date:'medium'}}
                    </td>
                    <td>
                        {{inventory.item.releaseDate | date:'medium'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <!-- Dialog Add Items -->
    <p-dialog [(visible)]="addItemsDialog" [style]="{ width: '600px' }" header="Agregar Artículos" [modal]="true"
        styleClass="p-fluid" (onHide)="hideAddItemsDialog()">
        <ng-template pTemplate="content">
            <div class="flex flex-column align-items-left w-full gap-3 surface-border">
                <form [formGroup]="addItemsForm">
                    <div class="field">
                        <label for="inventoryType">Tipo de inventario <span class="req">*</span></label>
                        <p-dropdown formControlName="inventoryType" id="inventoryType" appendTo="body"
                            optionLabel="name" optionValue="id" [readonly]="isScanning || !!scannedItems.length"
                            [disabled]="isScanning || !!scannedItems.length"
                            [options]="inventoryTypes" placeholder="Seleccione un tipo de inventario"></p-dropdown>
                    </div>
                    <div class="field" *ngIf="!showScanningTable">
                        <label for="description">Descripción <span class="req">*</span></label>
                        <input formControlName="description" id="description" #description type="text" pInputText
                            id="name" [readonly]="isScanning" [disabled]="isScanning"
                            placeholder="Agrega una descripción a los artículos a escanear" class="uppercase-input" />
                    </div>
                    <div class="field" *ngIf="!isScanning">
                        <button pButton pRipple label="Escanear Articulos" icon="pi pi-qrcode"
                            class="p-button-primary mr-2" (click)="startQrScan()"
                            [disabled]="addItemsForm.controls['inventoryType'].invalid || addItemsForm.controls['description'].invalid"></button>
                    </div>
                </form>

                <div *ngIf="isScanning && !showScanningTable">
                    <app-qr-scanner [type]="addItemsForm.controls['inventoryType'].value"
                        [description]="addItemsForm.controls['description'].value" (onFinish)="onFinishQrScan($event)"
                        [scannedItems]="scannedItems"></app-qr-scanner>
                </div>
                <!-- Item Scan table-->
                <p-table *ngIf="showScanningTable" [(selection)]="selectedItemsToDelete" dataKey="code"
                    [value]="scannedItems" [rowHover]="true" [scrollable]="true" scrollHeight="400px"
                    styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="caption" #myTemplate>
                        <div class="flex align-items-center justify-content-between">
                            <p-button severity="danger" label="Eliminar" icon="pi pi-trash"
                                (onClick)="deleteNewInventoryItems()"
                                [disabled]="!selectedItemsToDelete || !selectedItemsToDelete.length" />
                            <p-button severity="primary" label="Escanear mas códigos" icon="pi pi-plus-circle"
                                (onClick)="onAddMoreItems()" />
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                <p-tableHeaderCheckbox />
                            </th>
                            <th style="width:40%">
                                Código de artículo
                            </th>
                            <th style="width:40%">
                                Descripción
                            </th>
                            <th style="width:20%">
                                Cantidad
                            </th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-editing="editing">
                        <tr>
                            <td>
                                <p-tableCheckbox [value]="item" />
                            </td>
                            <td>
                                {{ item.code }}
                            </td>
                            <td [pEditableColumn]="item.description" pEditableColumnField="Descripción">
                                <p-cellEditor>
                                    <ng-template pTemplate="input">
                                        <input pInputText type="text" [(ngModel)]="item.description" class="uppercase-input" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ item.description }}
                                    </ng-template>
                                </p-cellEditor>
                            </td>
                            <td [pEditableColumn]="item.quantity" pEditableColumnField="Cantidad">
                                <p-cellEditor *ngIf="item.type == constants.QUANTITY">
                                    <ng-template pTemplate="input" >
                                        <input pInputText type="text" [(ngModel)]="item.quantity" />
                                    </ng-template>
                                    <ng-template pTemplate="output">
                                        {{ item.quantity }}
                                    </ng-template>
                                </p-cellEditor>
                                <p *ngIf="item.type == constants.ARTICLE">
                                    {{ item.quantity }}
                                </p>
                            </td>
                            <td>
                                <p-button icon="pi pi-trash" severity="danger" [rounded]="true" [outlined]="true"
                                    (onClick)="deleteNewInventoryItem(item)" />
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="field" *ngIf="showScanningTable">
                    <p-button size="large" icon="pi pi-save" label="Paletizar" (onClick)="palletize()" />
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <!-- Dialog Pallets -->
    <p-dialog [(visible)]="addPalletDialog" [style]="{ width: '600px' }" header="Paletizar Artículos" [modal]="true"
        styleClass="p-fluid" (onHide)="hideAddPalletDialog()">
        <ng-template pTemplate="content">
            <div class="flex flex-column align-items-left w-full gap-3 surface-border">
                <div *ngIf="true">
                    <app-qr-pallet-scanner [warehouse]="selectedWarehouse.name" [accountId]="selectedAccount.accountId"
                        [palletData]="palletData" (onFinish)="onFinishScan($event)"></app-qr-pallet-scanner>
                </div>
            </div>
        </ng-template>
    </p-dialog>


    <!-- confirm dialog-->
    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>