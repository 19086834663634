import { Component } from '@angular/core';
import { Warehouse } from '../../../domain/warehouse.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AccountDropdown } from '../../../domain/accountDropdown.model';
import { AccountService } from '../../../services/account.service';
import { InventoryService } from '../../../services/inventory.service';
import { PageableItems } from '../../../domain/inventory.model';
import { TableLazyLoadEvent } from 'primeng/table';
import { Table } from 'primeng/table';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Dropdown } from '../../../domain/dropdown.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Inventory, NewItem, Constants, NewPallet, InventoryCreation } from '../../../domain/inventory.model';
import { AuthService } from '../../../services/auth.service';
import { Account } from '../../../domain/account.model';

@Component({
  selector: 'app-inventory',
  standalone: false,
  templateUrl: './inventory.component.html',
  styleUrl: './inventory.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class InventoryComponent {
  private searchSubject = new Subject<string>();
  rows: number = 50;
  warehouses: Warehouse[] = [
    {
      id: 1,
      name: 'Bodega Zapopan'
    }
  ];
  selectedWarehouse: Warehouse = this.warehouses[0];
  items: Inventory[] = [];
  selectedAccount: AccountDropdown = { accountId: 0, name: '', isDeleted: false };
  account_array: AccountDropdown[] = [];
  totalRecords: number = 0;
  searchValue: string = '';

  // Add Items Dialog
  addItemsDialog: boolean = false;
  inventoryTypes: Dropdown[];
  addItemsForm: FormGroup = this.formBuilder.group({
    inventoryType: ['', Validators.required],
    description: ['', Validators.required, Validators.minLength],
  });
  isScanning: boolean = false;
  showScanningTable: boolean = false;
  scannedItems: NewItem[] = [];

  // Add Pallet Dialog
  addPalletDialog: boolean = false;
  palletData: NewPallet = {};
  scanSuccess: boolean = false;
  selectedItemsToDelete: NewItem[] = [];
  constants = Constants;
  userAccount: Account;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private accountService: AccountService,
    private inventoryService: InventoryService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.inventoryTypes = inventoryService.getInventoryTypes();
    this.userAccount = authService.getUserAccount();
    this.palletData = {
      depth: this.userAccount.palletLength,
      width: this.userAccount.palletWidth,
      height: this.userAccount.palletHeight,
    }
  }

  ngOnInit(): void {
    this.getAllAccounts();
    // Configure search debounce
    this.searchSubject.pipe(
      debounceTime(600)
    ).subscribe(searchValue => {
      this.initSearch();
    });
  }

  getAllAccounts() {
    this.accountService.getAllDropdownAccounts().subscribe(
      (data: AccountDropdown[]) => {
        this.account_array = data;
        this.selectedAccount = data[0];
        this.initSearch();
      }
    );
  }

  initSearch() {
    this.loadItemsLazy({ first: 0, rows: this.rows });
  }

  loadItemsLazy(event: TableLazyLoadEvent) {
    if (!this.selectedAccount?.accountId) { return; }
    let page: number = (event.first! / event.rows!) || 0;
    let size: number = event.rows || 0;

    if (this.searchValue) {
      this.searchItems(this.selectedWarehouse?.name, this.selectedAccount?.accountId, page, size, this.searchValue);
    } else {
      this.loadItems(this.selectedWarehouse?.name, this.selectedAccount?.accountId, page, size);
    }
  }

  loadItems(warehouse: string, accountId: number, page: number, size: number) {
    this.inventoryService.getItemsByWarehouseAndCompany(warehouse,
      accountId, page, size).subscribe(
        (data: PageableItems) => {
          this.items = data.content;
          this.totalRecords = data.totalElements;
        }
      );
  }

  searchItems(warehouse: string, accountId: number, page: number, size: number, description: string) {
    this.inventoryService.searchItemsByDescription(warehouse,
      accountId, page, size, description).subscribe(
        (data: PageableItems) => {
          this.items = data.content;
          this.totalRecords = data.totalElements;
        }
      );
  }

  onSearch() {
    this.searchSubject.next(this.searchValue);
  }

  clear(table: Table) {
    //table.clear();
    this.searchValue = '';
    this.initSearch();
  }

  onAddItems() {
    this.initItemsDialog();
    this.addItemsDialog = true;
  }

  hideAddItemsDialog() {
    if(this.addPalletDialog) return;

    this.confirmationService.confirm({
        message: '¿Estás seguro de que deseas cerrar la creación del inventario?',
        accept: () => {
            this.addItemsDialog = false;
        },
        reject: () => {
            this.addItemsDialog = true;
        },
    });
  }

  hideAddPalletDialog() {
    if(this.addPalletDialog) return;
    this.confirmationService.confirm({
        message: '¿Estás seguro de que deseas cerrar la creación del inventario?',
        accept: () => {
            this.addPalletDialog = false;
        },
        reject: () => {
            this.addPalletDialog = true;
        },
    });
  }

  initItemsDialog() {
    this.addItemsDialog = false;
    this.isScanning = false;
    this.showScanningTable = false;
    this.scannedItems = [];
    this.addItemsForm.reset();
  }

  startQrScan() {
    this.isScanning = true;
  }

  onFinishQrScan(scannedItems: NewItem[]) {
    this.showScanningTable = true;
    this.scannedItems = scannedItems;
  }

  onFinishScan(palletData: NewPallet) {
    const inventoryCreation: InventoryCreation = {
      items: this.scannedItems,
      pallet: palletData,
      warehouse: this.selectedWarehouse.name,
      accountId: this.selectedAccount.accountId
    }
    this.inventoryService.create(inventoryCreation);

    this.inventoryService.create(inventoryCreation).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Inventario creado', life: 5000 });
      this.addPalletDialog = false;
      this.initSearch();
    }, resp => {
      console.error('Error creating inventory', resp);
      this.messageService.add({severity: 'error', summary: resp.error.message});
    });
  }

  deleteNewInventoryItems() {
    this.scannedItems = this.scannedItems.filter((val) => !this.selectedItemsToDelete?.includes(val));
    this.selectedItemsToDelete = [];
  }

  deleteNewInventoryItem(item: NewItem) {
    this.scannedItems = this.scannedItems.filter((val) => val.code !== item.code);
  }

  onAddMoreItems() {
    this.showScanningTable = false;
    this.isScanning = false;
    if (this.addItemsForm.controls['inventoryType'].value == this.constants.QUANTITY) {
      this.addItemsForm.controls['description'].reset();
    } 
  }

  palletize() {
    this.addItemsDialog = false;
    this.addPalletDialog = true;
  }

  onChangeAccount() {
    this.initSearch();
  }

}
