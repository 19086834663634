<p-sidebar [style]="{ width: '400px' }" styleClass="p-fluid" [(visible)]="userProfileVisible" (onHide)="onCloseSidebar()" position="right" [modal]="true">
    <ng-template pTemplate="header"><h4>Perfil de usuario</h4></ng-template>
    <ng-template pTemplate="content">
      <form [formGroup]="userForm">
        <div class="field">
            <label for="name">Nombre</label>
            <input formControlName="name" type="text" pInputText id="name" [(ngModel)]="user.name" required autofocus/>
            <small class="p-error" *ngIf="submitted && userForm.controls['name'].errors?.['required']">Nombre es requerido.</small>
        </div>
        <div class="field">
            <label for="lastname">Apellido</label>
            <input formControlName="lastname" type="text" pInputText id="lastname" [(ngModel)]="user.lastname" required autofocus/>
            <small class="p-error" *ngIf="submitted && userForm.controls['lastname'].errors?.['required']">Apellido es requerido.</small>
        </div>
        <div class="field">
            <label for="email">Correo</label>
            <input formControlName="email" type="email" pInputText id="email" [(ngModel)]="user.email" required autofocus [readOnly]="true"/>
            <small class="p-error" *ngIf="submitted && userForm.controls['email'].errors?.['required']">Correo es requerido.</small>
            <small class="p-error" *ngIf="submitted && userForm.controls['email'].errors?.['email']">Formato de correo no valido.</small>
        </div>

        <div class="field">
            <p-button (click)="onSave()" label="Guardar" icon="pi pi-check" class="p-button-text"></p-button>
        </div>
        <div class="field">
            <p-button (click)="onCloseSidebar()" label="Cancelar" icon="pi pi-times" class="p-button-text" severity="secondary"></p-button>          
        </div>
    </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
        <p-button (click)="onResetPassword()" label="Renovar contraseña" severity="danger" icon="pi pi-key" class="p-button-text" disabled="true"></p-button>
    </ng-template>
  </p-sidebar>

<!-- confirm dialog-->
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>