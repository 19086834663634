import { Component, OnInit } from '@angular/core';
import { Account } from '../../../domain/account.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AccountService } from '../../../services/account.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-accounts',
  standalone: false,
  templateUrl: './accounts.component.html',
  styleUrl: './accounts.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class AccountsComponent implements OnInit {

  accountDialog: boolean = false;
  selectedAccounts!: Account[] | null;
  submitted: boolean = false;
  readOnly: boolean = false;
  selectedAccount!: any;
  accountForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, this.noWhitespace(8)]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
    business_name: ['', [Validators.required, this.noWhitespace(5)]],
    observation: [''],
    service_type: ['', Validators.required],
    pallet_length: [''],
    pallet_width: [''],
    pallet_height: [''],
    pallets_quantity: [''],
    pallet_weight: [''],
    product_types: [''],
    reception_type: [''],
    order_frequency: [''],
    box_weight: [''],
    skus_quantity: [''],
    packaging_type: [''],
    pallet_type: [''],
    freight_unit: [''],
    freight_interior_number: [''],
    freight_exterior_number: [''],
    freight_street_name: [''],
    freight_neighborhood: [''],
    freight_city: [''],
    freight_zip_code: [''],
    freight_country: [''],
    freight_municipality: [''],
    freight_state: [''],
    order_fulfillment: [''],
  });

  noWhitespace(minLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const sanitizedValue = control.value?.replace(/[^\w]/g, '');// Remove non-printable characters
      if (!sanitizedValue || sanitizedValue.trim() === '') {
        return { 'required': true }; // Return error if value is only whitespace
      }
      if (sanitizedValue.trim().length < minLength) {
        return { 'minlength': { requiredLength: minLength, actualLength: sanitizedValue.trim().length } }; // Return error if length is less than minLength without counting spaces
      }
      return null; // Return null if validation succeeds
    };
  }

  account!: Account;
  accounts: Account[] = [
    {
      accountId: 123,
      name: 'Coca Cola',
      email: 'admin@cocacola.com',
      phone: '333256595',
      businessName: 'RGDH12225455',
      observation: 'Cliente regular de CDMX',
      serviceType: 'Fullfilment',
      palletsQuantity: 50,
      palletWeight: 250,
      palletLength: 160,
      palletWidth: 120,
      palletHeight: 180,
      productTypes: 'Refrescos',
      receptionType: 'Trailer',
      boxWeight: 400,
      skusQuantity: 10,
      packagingType: 'Emplayado',
      palletType: 'Doble',
      freightUnit: 'Camioneta',
      freightInteriorNumber: 'B',
      freightExteriorNumber: '485',
      freightStreetName: 'Mariano Otero',
      freightNeighborhood: 'Arboledas Sec. 1',
      freightCity: 'Zapopan',
      freightZipCode: '685656',
      freightCountry: 'Mexico',
      freightMunicipality: 'Zapopan',
      freightState: 'Jalisco',
      orderFulfillment: 'si',
      orderFrequency: 'Semanalmente',
      creationTime: new Date(),
      updateTime: new Date(),
      isDeleted: false
    }
  ];

  serviceTypes: any[] = [
    {
      id: 123,
      name: "In and Out Tarima",
    },
    {
      id: 1234,
      name: "Fullfilment",
    },
    {
      id: 12345,
      name: "Preparado de pedidos",
    }
  ];

  accounts2: any[] = [
    {
      id: 123,
      name: "Coca Cola",
    },
    {
      id: 1234,
      name: "BMW",
    },
    {
      id: 12345,
      name: "Don Julio",
    },
    {
      id: 123456,
      name: "Vinos America",
    },
    {
      id: 1234567,
      name: "IBM",
    }
  ];

  receptionTypes: any[] = [
    {
      id: 123,
      name: "Camioneta",
    },
    {
      id: 1234,
      name: "Rabon",
    },
    {
      id: 12345,
      name: "Trailer",
    },
    {
      id: 12345,
      name: "Grúa",
    },
    {
      id: 12345,
      name: "Contenedor",
    },
    {
      id: 12345,
      name: "Full",
    }
  ];

  palletTypes: String[] = ["Simple", "Doble"];

  constructor(
    private messageService: MessageService, 
    private confirmationService: ConfirmationService, 
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    public authService: AuthService
    ) { }

  ngOnInit(): void { 
    this.getAllAccounts();
  }

  getAllAccounts() {
    this.accountService.getAllAccounts().subscribe(
      (data: Account[]) => {
        this.accounts = data;
      }
    );
  }

  get formControl(): any {
    return this.accountForm.controls;
  }
  
  openNew() {
    this.account = {};
    this.submitted = false;
    this.accountDialog = true;
  }

  deleteSelectedAccounts() {
    this.confirmationService.confirm({
      message: 'Estas seguro que quieres eliminar los usuarios seleccionados?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const accountIds: number[] = this.selectedAccounts?.map(account => account.accountId!) ?? [];
        this.accountService.deleteAccounts(accountIds).subscribe(response => {
          this.account = {};
          this.selectedAccounts = null;
          this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Cuenta eliminada', life: 5000 });
          this.getAllAccounts();
        }, error => {
          console.error('Error deleting account', error);
        });
      }
    });
  }

  editAccount(account: Account) {
    this.account = { ...account };
    this.accountDialog = true;
  }

  viewAccount(account: Account) {
    this.account = { ...account };
    this.accountDialog = true;
    this.readOnly = true;
  }

  deleteAccount(account: Account) {
    this.confirmationService.confirm({
      message: 'Estas seguro que quieres eliminar a ' + account.email + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.accountService.deleteAccount(account).subscribe(response => {
          this.accounts = this.accounts.filter((val) => val.accountId !== account.accountId);
          this.account = {};
          this.selectedAccounts = null;
          this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario eliminado', life: 5000 });
          // this.getAllAccounts();
        }, error => {
          console.error('Error deleting user', error);
        });
      }
    });
  }

  hideDialog() {
    this.accountDialog = false;
    this.submitted = false;
    this.readOnly = false;
  }

  saveAccount() {
    this.submitted = true;
    if (this.accountForm.invalid) {
      return;
    }

    const newAccount: Account = {
      ...this.account
    };

    if (this.account.accountId) {
      this.updateAccount(newAccount);
    } else {
      this.createAccount(newAccount);
    }
  }

  createAccount(account: Account) {
    this.accountService.createAccount(account).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Cuenta creada', life: 5000 });
      this.accountDialog = false;
      this.account = {};
      this.getAllAccounts();
    }, error => {
      console.error('Error creating account', error);
      this.accountDialog = false;
      this.account = {};
    });
  }

  updateAccount(account: Account) {
    this.accountService.updateAccount(account).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Cuenta actualizada', life: 5000 });
      this.accountDialog = false;
      this.account = {};
      this.getAllAccounts();
    }, error => {
      console.error('Error updating account', error);
    });
  }

  exportToExcel() {
    this.exportAsExcelFile(this.accounts, 'usuarios.xlsx');
  }

  exportAsExcelFile(data: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, excelFileName);
  }

  get hasActionAccess(): boolean {
    return this.authService.canAccessAction('accounts', 'delete');
  }
}
