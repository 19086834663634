<h2>Usuarios</h2>
<!--Table-->
<div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">
            <button pButton pRipple label="Nuevo" icon="pi pi-plus" class="p-button-primary mr-2"
                (click)="openNew()"></button>
            <button pButton pRipple label="Eliminar" icon="pi pi-trash" class="p-button-danger"
                (click)="deleteSelectedUsers()" [disabled]="!selectedUsers || !selectedUsers.length"></button>
        </ng-template>

        <ng-template pTemplate="right">
            <!--<p-fileUpload mode="basic" accept="image/*" [maxFileSize]="1000000" label="Importar" chooseLabel="Importar"
                class="mr-2 inline-block"></p-fileUpload>-->
            <button pButton pRipple label="Exportar" icon="pi pi-upload" class="p-button-primary"
                (click)="exportToExcel()"></button>
        </ng-template>
    </p-toolbar>

    <p-table #dt [value]="users" [rows]="20" [paginator]="true" [globalFilterFields]="[]"
        [tableStyle]="{ 'min-width': '75rem' }" styleClass="p-datatable-sm" [(selection)]="selectedUsers"
        [rowHover]="true" dataKey="userId"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
        <ng-template pTemplate="caption">
            <div class="grid col-12">
                <div class="col col-12 xl:col-8">
                    <h5>Administrar Usuarios</h5>
                </div>
                <div class="col col-12 xl:col-4 flex align-items-center justify-content-end">
                    <div class="formgroup-inline">
                        <div class="field">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dt.filter($any($event.target)?.value, 'email', 'contains')"
                                    [value]="$any(dt.filters['email'])?.value" placeholder="Buscar por correo"
                                    class="p-column-filter" [style]="{'width':'100%'}">
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="name" style="min-width:15rem">Nombre <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="email" style="min-width:15rem">Email <p-sortIcon field="email"></p-sortIcon></th>
                <th pSortableColumn="account.name">Cuenta <p-sortIcon field="accountId"></p-sortIcon></th>
                <th pSortableColumn="role.name" style="min-width:10rem">Rol <p-sortIcon field="roleId"></p-sortIcon>
                </th>
                <th pSortableColumn="creationTime">Fecha de creación <p-sortIcon field="creationTime"></p-sortIcon></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>
                    <p-tableCheckbox [value]="user"></p-tableCheckbox>
                    {{user.userId}}
                </td>
                <td>{{ user.name}} {{user.lastname}}</td>
                <td>{{ user.email | lowercase}}</td>
                <td>{{ user.account?.name }}</td>
                <td>{{ user.role?.name }}</td>
                <td>{{ user.creationTime | date:'medium'}}</td>
                <td>
                    <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2"
                        (click)="viewUser(user)"></button>
                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2"
                        (click)="editUser(user)"></button>
                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                        (click)="deleteUser(user)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">En total hay {{ users ? users.length : 0
                }} usuarios.</div>
        </ng-template>
    </p-table>
</div>

<!-- Dialog -->
<p-dialog [(visible)]="userDialog" [style]="{ width: '500px' }" header="Detalles de Usuario" [modal]="true"
    styleClass="p-fluid" (onHide)="hideDialog()">
    <ng-template pTemplate="content">
        <form [formGroup]="userForm">
            <div class="field">
                <label for="name">Nombre</label>
                <input formControlName="name" type="text" pInputText id="name" [(ngModel)]="user.name" required
                    autofocus [readonly]="readOnly" />
                <small class="p-error" *ngIf="submitted && userForm.controls['name'].errors?.['required']">Nombre es
                    requerido.</small>
            </div>
            <div class="field">
                <label for="lastname">Apellido</label>
                <input formControlName="lastname" type="text" pInputText id="lastname" [(ngModel)]="user.lastname"
                    required autofocus [readonly]="readOnly" />
                <small class="p-error" *ngIf="submitted && userForm.controls['lastname'].errors?.['required']">Apellido
                    es requerido.</small>
            </div>
            <div class="field">
                <label for="email">Correo</label>
                <input formControlName="email" type="email" pInputText id="email" [(ngModel)]="user.email" required
                    autofocus [readonly]="readOnly" />
                <small class="p-error" *ngIf="submitted && userForm.controls['email'].errors?.['required']">Correo es
                    requerido.</small>
                <small class="p-error" *ngIf="submitted && userForm.controls['email'].errors?.['email']">Formato de
                    correo no valido.</small>
            </div>

            <div class="field">
                <label for="account">Cuenta</label>
                <p-dropdown formControlName="account" id="account" appendTo="body" [options]="accounts"
                    [ngModel]="user.account?.accountId" optionLabel="name" optionValue="accountId" [filter]="true"
                    filterBy="name" [showClear]="true" placeholder="Selecciona una cuenta" [readonly]="readOnly">
                    <ng-template pTemplate="selectedItem" let-selectedOption>
                        <div class="flex align-items-center gap-2">
                            <div>{{ selectedOption.name }}</div>
                        </div>
                    </ng-template>
                    <ng-template let-account pTemplate="item">
                        <div class="flex align-items-center gap-2">
                            <div>{{ account.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
                <small class="p-error" *ngIf="submitted && userForm.controls['account'].errors?.['required']">Cuenta es
                    requerido.</small>
            </div>

            <div class="field">
                <label for="role">Rol</label>
                <p-dropdown formControlName="role" id="role" appendTo="body" [ngModel]="user.role?.roleId"
                    optionLabel="name" optionValue="roleId" [options]="roles" [readonly]="readOnly"></p-dropdown>
                <small class="p-error" *ngIf="submitted && userForm.controls['role'].errors?.['required']">Rol es
                    requerido.</small>
            </div>
        </form>
    </ng-template>

    <ng-template pTemplate="footer">
        <!-- Sow when new or edit-->
        <div *ngIf="!readOnly">
            <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text"
                (click)="hideDialog()"></button>
            <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text"
                (click)="saveUser()"></button>
        </div>
        <!-- Sow when view-->
        <button *ngIf="readOnly" pButton pRipple label="Cerrar" icon="pi pi-times" class="p-button-text"
            (click)="hideDialog()"></button>

    </ng-template>
</p-dialog>

<!-- confirm dialog-->
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>