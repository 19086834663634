import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from '../../../domain/order';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-orders',
  standalone: false,
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class OrdersComponent {
  selectedOrders!: Order[] | null;
  order: Order = {};
  readOnly: boolean = false;
  orderDialog: boolean = false;

  orders: Order[] = [
    {
      order_id: 12345,
      order_request_id: 'VA-20240423-12345',
      service_type: 'Entrega en bodega',
      account_name: 'Vinos America',
      contact_method: 'Telefono',
      contact_value: '333025689',
      status: 'Creada',
      short_name: 'VA',
      authorized_person: 'Victor Lopez',
      interior_number: 'B',
      exterior_number: '485',
      street_name: 'Mariano Otero',
      neighborhood: 'Arboledas Sec. 1',
      city: 'Zapopan',
      zip_code: '685656',
      country: 'Mexico',
      municipality: 'Zapopan',
      state: 'Jalisco',
      floor: '1',
      delivery_number: 'VA-20240417-12345',
      appointment_datetime: new Date(),
      attachment_name: 'file_order_va.xls',
      attachment_size: '50mb',
      attachment_id: '54455',
      created_by: 'Victor Lopez',
      updated_by: 'Fernando Lopez',
      update_time: new Date(),
      creation_time: new Date(),
      close_date: new Date(),
      is_deleted: false
    },
    {
      order_id: 123456,
      order_request_id: 'CC-20240425-123456',
      service_type: 'Entrega en bodega',
      account_name: 'Coca Cola',
      contact_method: 'Telefono',
      contact_value: '333025689',
      status: 'En proceso',
      short_name: 'CC',
      authorized_person: 'Victor Lopez',
      interior_number: 'B',
      exterior_number: '485',
      street_name: 'Av. Lopez Mateos',
      neighborhood: 'El Mante',
      city: 'Zapopan',
      zip_code: '685655',
      country: 'Mexico',
      municipality: 'Zapopan',
      state: 'Jalisco',
      floor: '1',
      delivery_number: 'CC-20240425-123456',
      appointment_datetime: new Date(),
      attachment_name: 'file_order_cc.xls',
      attachment_size: '10mb',
      attachment_id: '544555',
      created_by: 'Victor Lopez',
      updated_by: 'Manuel Rodriguez',
      update_time: new Date(),
      creation_time: new Date(),
      close_date: new Date(),
      is_deleted: false
    }
  ];

  constructor(private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }


  openNew() { 
    this.router.navigateByUrl('/home/order/create');
  }

  deleteSelectedOrder() {
    this.confirmationService.confirm({
      message: 'Estas seguro que quieres eliminar las ordenes seleccionadas?',
      header: 'Confirmación',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.orders = this.orders.filter((val) => !this.selectedOrders?.includes(val));
        this.selectedOrders = null;
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Ordenes eliminadas', life: 5000 });
      }
    });
  }

  deleteOrder(order: Order) {
    this.confirmationService.confirm({
      message: 'Estas seguro que quieres eliminar la orden ' + order.order_request_id + '?',
      header: 'Confirmación',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.orders = this.orders.filter((val) => val.order_id !== order.order_id);
        this.selectedOrders = null;
        this.order = {};
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Orden eliminada', life: 3000 });
      }
    });
  }

  exportToExcel() {
    const now = new Date();
    const fileName = `orders_${now.toISOString().replace(/[-T:.]/g, '').slice(0, -5)}.xlsx`;
    this.exportAsExcelFile(this.orders, fileName);
  }

  exportAsExcelFile(data: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, excelFileName);
  }

  test() {
  }

  test2(diag:any) {
  }


  hideDialog() {
    this.orderDialog = false;
    this.readOnly = false;
  }


}
