import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ImageService } from '../../services/image.service';
import { InventoryService } from '../../services/inventory.service';
import { PalletService } from '../../services/pallet.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Item, NewPallet, Pallet, PalletCreation } from '../../domain/inventory.model';
import { Location } from '@angular/common';
import { Account } from '../../domain/account.model';
import { AuthService } from '../../services/auth.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-qr-pallet-scanner-v2',
  templateUrl: './qr-pallet-scanner-v2.component.html',
  styleUrl: './qr-pallet-scanner-v2.component.scss',
  providers: [ConfirmationService]
})
export class QrPalletScannerV2Component {
  items: Item[] = [];
  qrCode: string = '';
  warehouse: string = '';
  accountId: number = 0;
  submitted: boolean = false;
  selectedImages: string[] = [];
  imagesToUpload: File[] = [];
  flooring: string;
  floorings: string[];
  userAccount: Account;
  palletForm: FormGroup = this.formBuilder.group({
    depth: [0, Validators.required],
    width: [0, Validators.required],
    height: [0, Validators.required],
    notes: [''],
    flooring: [''],
    location: [''],
  });

  // Camera settings
  availableDevices: MediaDeviceInfo[] = [];
  selectedDevice: MediaDeviceInfo | undefined;
  cameraMenuItems: MenuItem[] = [];

  constructor(private messageService: MessageService, private formBuilder: FormBuilder, private imageService: ImageService,
    private inventoryService: InventoryService, private palletService: PalletService, private route: ActivatedRoute,
    private location: Location, private authService: AuthService, private router: Router, private confirmationService: ConfirmationService) {
    this.floorings = this.inventoryService.getPalletLocation();
    this.flooring = this.floorings[0];
    this.userAccount = this.authService.getUserAccount();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.warehouse = params['warehouse'] || '';
      this.accountId = params['accountId'] ? Number(params['accountId']) : 0;
      this.qrCode = params['palletCode'] || '';
    });

    const state = history.state;
    this.items = state.items || [];

    if (this.items.length === 0) {
      this.location.back();
    }

    this.palletForm.patchValue({
      depth: this.userAccount.palletLength || 0,
      width: this.userAccount.palletWidth || 0,
      height: this.userAccount.palletHeight || 0
    });

    this.listCameras();
    this.setHistoryState();
  }

  ngOnDestroy(): void {
    window.removeEventListener('popstate', this.onClose.bind(this));
  }

  private setHistoryState(): void {
    history.pushState(null, '');
    window.addEventListener('popstate', this.onClose.bind(this));
  }

  listCameras(): void {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.availableDevices = devices.filter(device => device.kind === 'videoinput');
      this.populateCameraMenu();
      if (this.availableDevices.length > 0) {
        this.selectedDevice = this.availableDevices[0];
      }
    });
  }

  populateCameraMenu(): void {
    this.cameraMenuItems = this.availableDevices.map((device, index) => ({
      label: device.label || `Cámara ${index + 1}`,
      command: () => this.selectCamera(device)
    }));
    this.cameraMenuItems = [
      {
        label: 'Cámaras',
        items: this.cameraMenuItems
      }
    ];
  }

  selectCamera(device: MediaDeviceInfo): void {
    this.selectedDevice = device;
  }

  get formControl(): any {
    return this.palletForm.controls;
  }

  onClose() {
    this.confirmationService.confirm({
      message: '¿Quieres cancelar el Paletizado?',
      header: 'Confirmación',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        window.removeEventListener('popstate', this.onClose.bind(this));
        this.router.navigate(['/home/inventory/articles']);
        //this.location.back();
      },
      reject: () => {
        history.pushState(null, '');
      },
    });
  }

  openCamera() {
    const cameraInput = document.getElementById('cameraInput') as HTMLInputElement;
    cameraInput.click();
  }

  onPhotoSelected(event: any) {
    const files: FileList = event.target.files;
    if (files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageService.compressImage(file).then((compressedFile) => {
          this.selectedImages.push(e.target.result);
          this.imagesToUpload.push(compressedFile);
        });
      };
      reader.readAsDataURL(file);
    }
  }

  resetQr() {
    this.submitted = false;
    this.qrCode = '';
  }

  removeImage(index: number) {
    this.selectedImages.splice(index, 1);
    this.imagesToUpload.splice(index, 1);
  }

  onScanSuccess(qrCode: string) {
    this.playSound();
    if (this.qrCode) {
      this.messageService.add({ severity: 'warn', summary: 'Código duplicado', detail: 'El pallet ya tiene un código asignado' });
      return;
    }

    this.palletService.validatePalletCode(this.warehouse, this.accountId, qrCode).subscribe(isValid => {
      if (isValid) {
        this.qrCode = qrCode;
        this.messageService.add({ severity: 'success', summary: 'Escaneado', detail: 'Código de Pallet agregado' });
      }
    }, error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message });
    });
  }

  playSound() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/beepSound2.mp3';
    audio.load();
    audio.play();
  }

  onSave() {
    this.submitted = true;

    if (!this.qrCode) {
      this.messageService.add({ severity: 'error', summary: 'Código requerido', detail: 'Escanea el código del Pallet antes de guardar' });
      return;
    }

    if (this.palletForm.valid) {
      const palletData: NewPallet = { ...this.palletForm.getRawValue(), qrCode: this.qrCode, warehouse: this.warehouse, account: { 'accountId': this.accountId } }
      const payload: PalletCreation = {
        pallet: palletData,
        items: this.items
      };

      this.inventoryService.createPalletAndAssignArticles(payload, this.warehouse, this.accountId)
        .pipe(
          switchMap((response: Pallet) => {
            const files: File[] = this.imagesToUpload || [];
            if (files.length > 0) {
              return this.imageService.uploadImages(files, response.palletId || '');
            }
            return of();
          })
        ).subscribe(() => {
          this.resetQr();
          this.messageService.add({ severity: 'success', summary: 'Pallet Guardado', detail: 'El Pallet fue registrado', life: 5000 });
          setTimeout(() => {
            this.router.navigate(['/home/inventory']);
          }, 2000);
        }, resp => {
          console.error('Error creating the pallet', resp);
          this.messageService.add({ severity: 'error', summary: resp.error.message });
        });
    }
  }

}
