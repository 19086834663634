import { Component, OnInit } from '@angular/core';
import { User } from '../../../domain/user.model';
import { Role } from '../../../domain/role.model';
import { AccountDropdown } from '../../../domain/accountDropdown.model';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { UserService } from '../../../services/user.service';
import { RoleService } from '../../../services/role.service';
import { AccountService } from '../../../services/account.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-users',
  standalone: false,
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class UsersComponent implements OnInit {

  userDialog: boolean = false;
  selectedUsers!: User[] | null;
  submitted: boolean = false;
  readOnly: boolean = false;
  selectedAccount!: any;
  userForm: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    account: ['', Validators.required],
    role: [null, Validators.required]
  });
  user!: User;
  users: User[] = [];
  roles: Role[] = [];
  accounts: AccountDropdown[] = [];

  constructor(private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    private accountService: AccountService,
    public authService: AuthService) { }

  ngOnInit(): void {
    this.getAllUsers();
    this.getAllRoles();
    this.getAllAccounts();
  }

  getAllUsers() {
    this.userService.getAllUsers().subscribe(
      (data: User[]) => {
        this.users = data;
      },
      error => {
        console.error('Error fetching users', error);
      }
    );
  }

  getAllRoles() {
    this.roleService.getAllRoles().subscribe(
      (data: Role[]) => {
        this.roles = data;
      }
    );
  }

  getAllAccounts() {
    this.accountService.getAllDropdownAccounts().subscribe(
      (data: AccountDropdown[]) => {
        this.accounts = data;
      }
    );
  }

  openNew() {
    this.user = {};
    this.submitted = false;
    this.userDialog = true;
  }

  deleteSelectedUsers() {
    this.confirmationService.confirm({
      message: 'Estas seguro que quieres eliminar los usuarios seleccionados?',
      header: 'Confirmación',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        const userIds: number[] = this.selectedUsers?.map(user => user.userId!) ?? [];
        this.userService.deleteUsers(userIds).subscribe(response => {
          this.user = {};
          this.selectedUsers = null;
          this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario eliminado', life: 5000 });
          this.getAllUsers();
        }, error => {
          console.error('Error deleting user', error);
        });
      }
    });
  }

  editUser(user: User) {
    this.user = { ...user };
    this.userDialog = true;
  }

  viewUser(user: User) {
    this.user = { ...user };
    this.userDialog = true;
    this.readOnly = true;
  }

  deleteUser(user: User) {
    this.confirmationService.confirm({
      message: 'Estas seguro que quieres eliminar a ' + user.email + '?',
      header: 'Confirmación',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.userService.deleteUser(user).subscribe(response => {
          this.user = {};
          this.selectedUsers = null;
          // this.users = this.users.filter((val) => val.userId !== user.userId);
          this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario eliminado', life: 5000 });
          this.getAllUsers();
        }, error => {
          console.error('Error deleting user', error);
        });
      }
    });
  }

  hideDialog() {
    this.userDialog = false;
    this.submitted = false;
    this.readOnly = false;
  }

  saveUser() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }

    const newUser: User = {
      ...this.userForm.value,
      ...this.user,
      role: {
        roleId: this.userForm.get('role')?.value
      },
      account: {
        accountId: this.userForm.get('account')?.value
      }
    };

    if (this.user.userId) {
      this.updateUser(newUser);
    } else {
      this.createUser(newUser);
    }
  }

  createUser(user: User) {
    this.userService.createUser(user).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario creado', life: 5000 });
      this.userDialog = false;
      this.user = {};
      this.getAllUsers();
    }, error => {
      console.error('Error creating user', error);
      this.userDialog = false;
      this.user = {};
    });
  }

  updateUser(user: User) {
    this.userService.updateUser(user).subscribe(response => {
      this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Usuario actualizado', life: 5000 });
      this.userDialog = false;
      this.user = {};
      this.getAllUsers();
    }, error => {
      console.error('Error updating user', error);
    });
  }

  exportToExcel() {
    const now = new Date();
    const fileName = `users_${now.toISOString().replace(/[-T:.]/g, '').slice(0, -5)}.xlsx`;
    this.exportAsExcelFile(this.users, fileName);
  }

  exportAsExcelFile(data: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, excelFileName);
  }

}
