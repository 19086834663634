import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { forwardRef, EventEmitter, signal, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as i1 from 'primeng/api';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { BanIcon } from 'primeng/icons/ban';
import { StarIcon } from 'primeng/icons/star';
import { StarFillIcon } from 'primeng/icons/starfill';
import { DomHandler } from 'primeng/dom';
import { UniqueComponentId } from 'primeng/utils';
const _c0 = (a0, a1) => ({
  "p-readonly": a0,
  "p-disabled": a1
});
const _c1 = a0 => ({
  "p-focus": a0
});
const _c2 = (a0, a1) => ({
  "p-rating-item-active": a0,
  "p-focus": a1
});
function Rating_ng_container_1_div_1_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 10);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("ngClass", ctx_r1.iconCancelClass)("ngStyle", ctx_r1.iconCancelStyle);
  }
}
function Rating_ng_container_1_div_1_BanIcon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "BanIcon", 11);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("styleClass", "p-rating-icon p-rating-cancel")("ngStyle", ctx_r1.iconCancelStyle);
    i0.ɵɵattribute("data-pc-section", "cancelIcon");
  }
}
function Rating_ng_container_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 5);
    i0.ɵɵlistener("click", function Rating_ng_container_1_div_1_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onOptionClick($event, 0));
    });
    i0.ɵɵelementStart(1, "span", 6)(2, "input", 7);
    i0.ɵɵlistener("focus", function Rating_ng_container_1_div_1_Template_input_focus_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onInputFocus($event, 0));
    })("blur", function Rating_ng_container_1_div_1_Template_input_blur_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onInputBlur($event));
    })("change", function Rating_ng_container_1_div_1_Template_input_change_2_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onChange($event, 0));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(3, Rating_ng_container_1_div_1_span_3_Template, 1, 2, "span", 8)(4, Rating_ng_container_1_div_1_BanIcon_4_Template, 1, 3, "BanIcon", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(10, _c1, ctx_r1.focusedOptionIndex() === 0 && ctx_r1.isFocusVisibleItem));
    i0.ɵɵattribute("data-pc-section", "cancelItem");
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-p-hidden-accessible", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("name", ctx_r1.name)("checked", ctx_r1.value === 0)("disabled", ctx_r1.disabled)("readonly", ctx_r1.readonly);
    i0.ɵɵattribute("aria-label", ctx_r1.cancelAriaLabel());
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.iconCancelClass);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.iconCancelClass);
  }
}
function Rating_ng_container_1_ng_template_2_ng_container_3_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 16);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngStyle", ctx_r1.iconOffStyle)("ngClass", ctx_r1.iconOffClass);
    i0.ɵɵattribute("data-pc-section", "offIcon");
  }
}
function Rating_ng_container_1_ng_template_2_ng_container_3_StarIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "StarIcon", 17);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngStyle", ctx_r1.iconOffStyle)("styleClass", "p-rating-icon");
    i0.ɵɵattribute("data-pc-section", "offIcon");
  }
}
function Rating_ng_container_1_ng_template_2_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Rating_ng_container_1_ng_template_2_ng_container_3_span_1_Template, 1, 3, "span", 14)(2, Rating_ng_container_1_ng_template_2_ng_container_3_StarIcon_2_Template, 1, 3, "StarIcon", 15);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.iconOffClass);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.iconOffClass);
  }
}
function Rating_ng_container_1_ng_template_2_ng_container_4_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 19);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngStyle", ctx_r1.iconOnStyle)("ngClass", ctx_r1.iconOnClass);
    i0.ɵɵattribute("data-pc-section", "onIcon");
  }
}
function Rating_ng_container_1_ng_template_2_ng_container_4_StarFillIcon_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "StarFillIcon", 17);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    i0.ɵɵproperty("ngStyle", ctx_r1.iconOnStyle)("styleClass", "p-rating-icon p-rating-icon-active");
    i0.ɵɵattribute("data-pc-section", "onIcon");
  }
}
function Rating_ng_container_1_ng_template_2_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Rating_ng_container_1_ng_template_2_ng_container_4_span_1_Template, 1, 3, "span", 18)(2, Rating_ng_container_1_ng_template_2_ng_container_4_StarFillIcon_2_Template, 1, 3, "StarFillIcon", 15);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.iconOnClass);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.iconOnClass);
  }
}
function Rating_ng_container_1_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵlistener("click", function Rating_ng_container_1_ng_template_2_Template_div_click_0_listener($event) {
      const star_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onOptionClick($event, star_r4 + 1));
    });
    i0.ɵɵelementStart(1, "span", 6)(2, "input", 7);
    i0.ɵɵlistener("focus", function Rating_ng_container_1_ng_template_2_Template_input_focus_2_listener($event) {
      const star_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onInputFocus($event, star_r4 + 1));
    })("blur", function Rating_ng_container_1_ng_template_2_Template_input_blur_2_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onInputBlur($event));
    })("change", function Rating_ng_container_1_ng_template_2_Template_input_change_2_listener($event) {
      const star_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onChange($event, star_r4 + 1));
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵtemplate(3, Rating_ng_container_1_ng_template_2_ng_container_3_Template, 3, 2, "ng-container", 13)(4, Rating_ng_container_1_ng_template_2_ng_container_4_Template, 3, 2, "ng-container", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const star_r4 = ctx.$implicit;
    const i_r5 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(9, _c2, star_r4 + 1 <= ctx_r1.value, star_r4 + 1 === ctx_r1.focusedOptionIndex() && ctx_r1.isFocusVisibleItem));
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-p-hidden-accessible", true);
    i0.ɵɵadvance();
    i0.ɵɵproperty("name", ctx_r1.name)("checked", ctx_r1.value === 0)("disabled", ctx_r1.disabled)("readonly", ctx_r1.readonly);
    i0.ɵɵattribute("aria-label", ctx_r1.starAriaLabel(star_r4 + 1));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.value || i_r5 >= ctx_r1.value);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.value && i_r5 < ctx_r1.value);
  }
}
function Rating_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Rating_ng_container_1_div_1_Template, 5, 12, "div", 3)(2, Rating_ng_container_1_ng_template_2_Template, 5, 12, "ng-template", 4);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.cancel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.starsArray);
  }
}
function Rating_ng_template_2_span_0_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Rating_ng_template_2_span_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r6 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 22);
    i0.ɵɵlistener("click", function Rating_ng_template_2_span_0_Template_span_click_0_listener($event) {
      i0.ɵɵrestoreView(_r6);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onOptionClick($event, 0));
    });
    i0.ɵɵtemplate(1, Rating_ng_template_2_span_0_ng_container_1_Template, 1, 0, "ng-container", 23);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngStyle", ctx_r1.iconCancelStyle);
    i0.ɵɵattribute("data-pc-section", "cancelIcon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.cancelIconTemplate);
  }
}
function Rating_ng_template_2_span_1_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Rating_ng_template_2_span_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "span", 24);
    i0.ɵɵlistener("click", function Rating_ng_template_2_span_1_Template_span_click_0_listener($event) {
      const star_r8 = i0.ɵɵrestoreView(_r7).$implicit;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.onOptionClick($event, star_r8 + 1));
    });
    i0.ɵɵtemplate(1, Rating_ng_template_2_span_1_ng_container_1_Template, 1, 0, "ng-container", 23);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const i_r9 = ctx.index;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵattribute("data-pc-section", "onIcon");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.getIconTemplate(i_r9));
  }
}
function Rating_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, Rating_ng_template_2_span_0_Template, 2, 3, "span", 20)(1, Rating_ng_template_2_span_1_Template, 2, 2, "span", 21);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.cancel);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.starsArray);
  }
}
const RATING_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => Rating),
  multi: true
};
/**
 * Rating is an extension to standard radio button element with theming.
 * @group Components
 */
class Rating {
  cd;
  config;
  /**
   * When present, it specifies that the element should be disabled.
   * @group Props
   */
  disabled;
  /**
   * When present, changing the value is not possible.
   * @group Props
   */
  readonly;
  /**
   * Number of stars.
   * @group Props
   */
  stars = 5;
  /**
   * When specified a cancel icon is displayed to allow removing the value.
   * @group Props
   */
  cancel = true;
  /**
   * Style class of the on icon.
   * @group Props
   */
  iconOnClass;
  /**
   * Inline style of the on icon.
   * @group Props
   */
  iconOnStyle;
  /**
   * Style class of the off icon.
   * @group Props
   */
  iconOffClass;
  /**
   * Inline style of the off icon.
   * @group Props
   */
  iconOffStyle;
  /**
   * Style class of the cancel icon.
   * @group Props
   */
  iconCancelClass;
  /**
   * Inline style of the cancel icon.
   * @group Props
   */
  iconCancelStyle;
  /**
   * Emitted on value change.
   * @param {RatingRateEvent} value - Custom rate event.
   * @group Emits
   */
  onRate = new EventEmitter();
  /**
   * Emitted when the rating is cancelled.
   * @param {Event} value - Browser event.
   * @group Emits
   */
  onCancel = new EventEmitter();
  /**
   * Emitted when the rating receives focus.
   * @param {Event} value - Browser event.
   * @group Emits
   */
  onFocus = new EventEmitter();
  /**
   * Emitted when the rating loses focus.
   * @param {Event} value - Browser event.
   * @group Emits
   */
  onBlur = new EventEmitter();
  templates;
  onIconTemplate;
  offIconTemplate;
  cancelIconTemplate;
  value;
  onModelChange = () => {};
  onModelTouched = () => {};
  starsArray;
  isFocusVisibleItem = true;
  focusedOptionIndex = signal(-1);
  name;
  constructor(cd, config) {
    this.cd = cd;
    this.config = config;
  }
  ngOnInit() {
    this.name = this.name || UniqueComponentId();
    this.starsArray = [];
    for (let i = 0; i < this.stars; i++) {
      this.starsArray[i] = i;
    }
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'onicon':
          this.onIconTemplate = item.template;
          break;
        case 'officon':
          this.offIconTemplate = item.template;
          break;
        case 'cancelicon':
          this.cancelIconTemplate = item.template;
          break;
      }
    });
  }
  onOptionClick(event, value) {
    if (!this.readonly && !this.disabled) {
      this.onOptionSelect(event, value);
      this.isFocusVisibleItem = false;
      const firstFocusableEl = DomHandler.getFirstFocusableElement(event.currentTarget, '');
      firstFocusableEl && DomHandler.focus(firstFocusableEl);
    }
  }
  onOptionSelect(event, value) {
    this.focusedOptionIndex.set(value);
    this.updateModel(event, value || null);
  }
  onChange(event, value) {
    this.onOptionSelect(event, value);
    this.isFocusVisibleItem = true;
  }
  onInputBlur(event) {
    this.focusedOptionIndex.set(-1);
    this.onBlur.emit(event);
  }
  onInputFocus(event, value) {
    this.focusedOptionIndex.set(value);
    this.onFocus.emit(event);
  }
  updateModel(event, value) {
    this.value = value;
    this.onModelChange(this.value);
    this.onModelTouched();
    if (!value) {
      this.onCancel.emit();
    } else {
      this.onRate.emit({
        originalEvent: event,
        value
      });
    }
  }
  cancelAriaLabel() {
    return this.config.translation.clear;
  }
  starAriaLabel(value) {
    return value === 1 ? this.config.translation.aria.star : this.config.translation.aria.stars.replace(/{star}/g, value);
  }
  getIconTemplate(i) {
    return !this.value || i >= this.value ? this.offIconTemplate : this.onIconTemplate;
  }
  writeValue(value) {
    this.value = value;
    this.cd.detectChanges();
  }
  registerOnChange(fn) {
    this.onModelChange = fn;
  }
  registerOnTouched(fn) {
    this.onModelTouched = fn;
  }
  setDisabledState(val) {
    this.disabled = val;
    this.cd.markForCheck();
  }
  get isCustomIcon() {
    return this.templates && this.templates.length > 0;
  }
  static ɵfac = function Rating_Factory(t) {
    return new (t || Rating)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.PrimeNGConfig));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Rating,
    selectors: [["p-rating"]],
    contentQueries: function Rating_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      disabled: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "disabled", "disabled", booleanAttribute],
      readonly: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "readonly", "readonly", booleanAttribute],
      stars: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "stars", "stars", numberAttribute],
      cancel: [i0.ɵɵInputFlags.HasDecoratorInputTransform, "cancel", "cancel", booleanAttribute],
      iconOnClass: "iconOnClass",
      iconOnStyle: "iconOnStyle",
      iconOffClass: "iconOffClass",
      iconOffStyle: "iconOffStyle",
      iconCancelClass: "iconCancelClass",
      iconCancelStyle: "iconCancelStyle"
    },
    outputs: {
      onRate: "onRate",
      onCancel: "onCancel",
      onFocus: "onFocus",
      onBlur: "onBlur"
    },
    features: [i0.ɵɵProvidersFeature([RATING_VALUE_ACCESSOR]), i0.ɵɵInputTransformsFeature],
    decls: 4,
    vars: 8,
    consts: [["customTemplate", ""], [1, "p-rating", 3, "ngClass"], [4, "ngIf", "ngIfElse"], ["class", "p-rating-item p-rating-cancel-item", 3, "ngClass", "click", 4, "ngIf"], ["ngFor", "", 3, "ngForOf"], [1, "p-rating-item", "p-rating-cancel-item", 3, "click", "ngClass"], [1, "p-hidden-accessible"], ["type", "radio", "value", "0", 3, "focus", "blur", "change", "name", "checked", "disabled", "readonly"], ["class", "p-rating-icon p-rating-cancel", 3, "ngClass", "ngStyle", 4, "ngIf"], [3, "styleClass", "ngStyle", 4, "ngIf"], [1, "p-rating-icon", "p-rating-cancel", 3, "ngClass", "ngStyle"], [3, "styleClass", "ngStyle"], [1, "p-rating-item", 3, "click", "ngClass"], [4, "ngIf"], ["class", "p-rating-icon", 3, "ngStyle", "ngClass", 4, "ngIf"], [3, "ngStyle", "styleClass", 4, "ngIf"], [1, "p-rating-icon", 3, "ngStyle", "ngClass"], [3, "ngStyle", "styleClass"], ["class", "p-rating-icon p-rating-icon-active", 3, "ngStyle", "ngClass", 4, "ngIf"], [1, "p-rating-icon", "p-rating-icon-active", 3, "ngStyle", "ngClass"], ["class", "p-rating-icon p-rating-cancel", 3, "ngStyle", "click", 4, "ngIf"], ["class", "p-rating-icon", 3, "click", 4, "ngFor", "ngForOf"], [1, "p-rating-icon", "p-rating-cancel", 3, "click", "ngStyle"], [4, "ngTemplateOutlet"], [1, "p-rating-icon", 3, "click"]],
    template: function Rating_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 1);
        i0.ɵɵtemplate(1, Rating_ng_container_1_Template, 3, 2, "ng-container", 2)(2, Rating_ng_template_2_Template, 2, 2, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        const customTemplate_r10 = i0.ɵɵreference(3);
        i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction2(5, _c0, ctx.readonly, ctx.disabled));
        i0.ɵɵattribute("data-pc-name", "rating")("data-pc-section", "root");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.isCustomIcon)("ngIfElse", customTemplate_r10);
      }
    },
    dependencies: () => [i2.NgClass, i2.NgForOf, i2.NgIf, i2.NgTemplateOutlet, i2.NgStyle, StarFillIcon, StarIcon, BanIcon],
    styles: ["@layer primeng{.p-rating{display:inline-flex;position:relative;align-items:center}.p-rating-icon{cursor:pointer}.p-rating.p-rating-readonly .p-rating-icon{cursor:default}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Rating, [{
    type: Component,
    args: [{
      selector: 'p-rating',
      template: `
        <div class="p-rating" [ngClass]="{ 'p-readonly': readonly, 'p-disabled': disabled }" [attr.data-pc-name]="'rating'" [attr.data-pc-section]="'root'">
            <ng-container *ngIf="!isCustomIcon; else customTemplate">
                <div *ngIf="cancel" [attr.data-pc-section]="'cancelItem'" (click)="onOptionClick($event, 0)" [ngClass]="{ 'p-focus': focusedOptionIndex() === 0 && isFocusVisibleItem }" class="p-rating-item p-rating-cancel-item">
                    <span class="p-hidden-accessible" [attr.data-p-hidden-accessible]="true">
                        <input
                            type="radio"
                            value="0"
                            [name]="name"
                            [checked]="value === 0"
                            [disabled]="disabled"
                            [readonly]="readonly"
                            [attr.aria-label]="cancelAriaLabel()"
                            (focus)="onInputFocus($event, 0)"
                            (blur)="onInputBlur($event)"
                            (change)="onChange($event, 0)"
                        />
                    </span>
                    <span *ngIf="iconCancelClass" class="p-rating-icon p-rating-cancel" [ngClass]="iconCancelClass" [ngStyle]="iconCancelStyle"></span>
                    <BanIcon *ngIf="!iconCancelClass" [styleClass]="'p-rating-icon p-rating-cancel'" [ngStyle]="iconCancelStyle" [attr.data-pc-section]="'cancelIcon'" />
                </div>
                <ng-template ngFor [ngForOf]="starsArray" let-star let-i="index">
                    <div class="p-rating-item" [ngClass]="{ 'p-rating-item-active': star + 1 <= value, 'p-focus': star + 1 === focusedOptionIndex() && isFocusVisibleItem }" (click)="onOptionClick($event, star + 1)">
                        <span class="p-hidden-accessible" [attr.data-p-hidden-accessible]="true">
                            <input
                                type="radio"
                                value="0"
                                [name]="name"
                                [checked]="value === 0"
                                [disabled]="disabled"
                                [readonly]="readonly"
                                [attr.aria-label]="starAriaLabel(star + 1)"
                                (focus)="onInputFocus($event, star + 1)"
                                (blur)="onInputBlur($event)"
                                (change)="onChange($event, star + 1)"
                            />
                        </span>
                        <ng-container *ngIf="!value || i >= value">
                            <span class="p-rating-icon" *ngIf="iconOffClass" [ngStyle]="iconOffStyle" [ngClass]="iconOffClass" [attr.data-pc-section]="'offIcon'"></span>
                            <StarIcon *ngIf="!iconOffClass" [ngStyle]="iconOffStyle" [styleClass]="'p-rating-icon'" [attr.data-pc-section]="'offIcon'" />
                        </ng-container>
                        <ng-container *ngIf="value && i < value">
                            <span class="p-rating-icon p-rating-icon-active" *ngIf="iconOnClass" [ngStyle]="iconOnStyle" [ngClass]="iconOnClass" [attr.data-pc-section]="'onIcon'"></span>
                            <StarFillIcon *ngIf="!iconOnClass" [ngStyle]="iconOnStyle" [styleClass]="'p-rating-icon p-rating-icon-active'" [attr.data-pc-section]="'onIcon'" />
                        </ng-container>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #customTemplate>
                <span *ngIf="cancel" (click)="onOptionClick($event, 0)" class="p-rating-icon p-rating-cancel" [ngStyle]="iconCancelStyle" [attr.data-pc-section]="'cancelIcon'">
                    <ng-container *ngTemplateOutlet="cancelIconTemplate"></ng-container>
                </span>
                <span *ngFor="let star of starsArray; let i = index" class="p-rating-icon" (click)="onOptionClick($event, star + 1)" [attr.data-pc-section]="'onIcon'">
                    <ng-container *ngTemplateOutlet="getIconTemplate(i)"></ng-container>
                </span>
            </ng-template>
        </div>
    `,
      providers: [RATING_VALUE_ACCESSOR],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-rating{display:inline-flex;position:relative;align-items:center}.p-rating-icon{cursor:pointer}.p-rating.p-rating-readonly .p-rating-icon{cursor:default}}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i1.PrimeNGConfig
  }], {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    readonly: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    stars: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }],
    cancel: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    iconOnClass: [{
      type: Input
    }],
    iconOnStyle: [{
      type: Input
    }],
    iconOffClass: [{
      type: Input
    }],
    iconOffStyle: [{
      type: Input
    }],
    iconCancelClass: [{
      type: Input
    }],
    iconCancelStyle: [{
      type: Input
    }],
    onRate: [{
      type: Output
    }],
    onCancel: [{
      type: Output
    }],
    onFocus: [{
      type: Output
    }],
    onBlur: [{
      type: Output
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class RatingModule {
  static ɵfac = function RatingModule_Factory(t) {
    return new (t || RatingModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: RatingModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, StarFillIcon, StarIcon, BanIcon, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, StarFillIcon, StarIcon, BanIcon],
      exports: [Rating, SharedModule],
      declarations: [Rating]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { RATING_VALUE_ACCESSOR, Rating, RatingModule };
