import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Order } from '../../../domain/order';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-create-order',
  standalone: false,
  templateUrl: './create-order.component.html',
  styleUrl: './create-order.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class CreateOrderComponent implements OnInit {
  order: Order = {};
  submitted: boolean = false;
  readOnly: boolean = false;
  minDate?: Date;
  date?: Date;
  orderForm: FormGroup = this.formBuilder.group({
    service_type: ['', Validators.required],
    account_name: ['', Validators.required],
    contact_method: ['', Validators.required],
    contact_value: ['', Validators.required],
    authorized_person: ['', Validators.required],
    street_name: ['', Validators.required],
    interior_number: ['', Validators.required],
    exterior_number: ['', Validators.required],
    zip_code: ['', Validators.required],
    city: ['', Validators.required],
    municipality: ['', Validators.required],
    state: ['', Validators.required],
    floor: ['', Validators.required],
    neighborhood: ['', Validators.required],
    adress_notes: ['', Validators.required],
    appointment_datetime: ['', Validators.required],
  });

  serviceTypes: any[] = [
    {
      id: 123,
      name: "In and Out Tarima",
    },
    {
      id: 1234,
      name: "Fullfilment",
    },
    {
      id: 12345,
      name: "Preparado de pedidos",
    }
  ];

  contactTypes: any[] = [
    {
      name: "Telefono",
    },
    {
      name: "Correo",
    },
    {
      name: "Fax",
    }
  ];

  accounts: any[] = [
    {
      id: 123,
      name: "Coca Cola",
    },
    {
      id: 1234,
      name: "BMW",
    },
    {
      id: 12345,
      name: "Don Julio",
    },
    {
      id: 123456,
      name: "Vinos America",
    },
    {
      id: 1234567,
      name: "IBM",
    }
  ];

  constructor(private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setHours(this.minDate.getHours() + 12);
    console.log(this.minDate);
    
  }
  
  get formControl(): any {
    return this.orderForm.controls;
  }

}
