import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { User } from '../../../domain/user.model'
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user-profile',
  standalone: false,
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
  providers: [ConfirmationService]
})
export class UserProfileComponent implements OnInit {
  @Input() userProfileVisible: any;
  @Output() userSidebarClosed: EventEmitter<any> = new EventEmitter<any>();
  submitted: boolean = false;
  userForm: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    lastname: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });

  user: User = {};

  constructor(
    private messageService: MessageService, 
    private confirmationService: ConfirmationService, 
    private formBuilder: FormBuilder, 
    private authService: AuthService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.loadUserDetails();
  }

  private loadUserDetails() {
    const user = this.authService.getUserDetails();
    this.user = user;
  }

  onCloseSidebar() {
    this.loadUserDetails();
    this.userSidebarClosed.emit();
  }

  onSave() {
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    this.showConfirmMessage('Estas seguro que quieres cambiar la información de usuario?', () => {
      this.userService.updateUser(this.user).subscribe(response => {
        this.user = response;
        this.authService.setUserDetails(this.user);
        this.userSidebarClosed.emit();
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Se ha actualizado el perfil.', life: 5000 });
      }, error => {
        console.error('Error updating user', error);
      });
    });
  }

  onResetPassword() {
    this.showConfirmMessage('Estas seguro que quieres renovar la contraseña?', () => {
      this.userService.resetPassword().subscribe(response => {
        this.messageService.add({ severity: 'success', summary: 'Exitoso', detail: 'Hemos generado su nueva contraseña.. Revise su correo.', life: 5000 });
        this.userSidebarClosed.emit();
      }, error => {
        console.error('Error updating password', error);
      });
    });
  }

  showConfirmMessage(message: string, acceptFunction: () => void) {
    this.confirmationService.confirm({
      message: message,
      header: 'Confirmación',
      icon: 'pi pi-exclamation-triangle',
      accept: acceptFunction,
      acceptLabel: 'Sí',
      rejectLabel: 'No',
    });
  }

}
