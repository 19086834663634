<h2>Artículos</h2>
<!--Table-->
<div class="card">

    <form [formGroup]="searchForm" (ngSubmit)="search()" class="mb-4 gap-2 p-4 surface-card shadow-2 border-round ">
        <div class="formgroup-inline">

            <div class="field col-12 xl:col-2">
                <label for="warehouse" class="block font-bold mb-2">Bodega</label>
                <p-dropdown id="warehouse" optionLabel="name" optionValue="name" formControlName="warehouse"
                    [options]="warehouses" placeholder="Selecciona una bodega" optionLabel="name"
                    [style]="{'width':'100%'}"></p-dropdown>
            </div>

            <div class="field col-12 xl:col-3">
                <label for="category" class="block font-bold mb-2">Cuenta</label>
                <p-dropdown [options]="accounts" formControlName="accountId" placeholder="Selecciona una cuenta"
                    optionLabel="name" [resetFilterOnHide]="true" [filter]="true" lazy="true"
                    (onFilter)="loadAccounts($event)" [showClear]="true" placeholder="Selecciona una Cuenta"
                    optionValue="accountId" autofocus>
                </p-dropdown>
            </div>

            <div class="field col-12 xl:col-2">
                <label for="code" class="block font-bold mb-2">Código de Artículo</label>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-code"></i>
                    <input pInputText id="code" formControlName="code" placeholder="Buscar por Código" class="w-full">
                </span>
            </div>

            <div class="field col-12 xl:col-4">
                <label for="description" class="block font-bold mb-2">Descripción</label>
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-search"></i>
                    <input pInputText id="description" formControlName="description"
                        placeholder="Buscar por Descripción" class="w-full">
                </span>
            </div>

            <p-accordion class="w-full" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <span class="flex align-items-center gap-2 w-full">
                            <span class="font-bold white-space-nowrap">
                                Búsqueda avanzada
                            </span>
                        </span>
                    </ng-template>
                    <div class="formgroup-inline">
                        <div class="field col-12 xl:col-2">
                            <label for="palletCode" class="block font-bold mb-2">Código de Palet</label>
                            <span class="p-input-icon-left w-full">
                                <i class="pi pi-code"></i>
                                <input pInputText id="palletCode" formControlName="palletCode"
                                    placeholder="Buscar por Código de Pallet" class="w-full">
                            </span>
                        </div>

                        <div class="field col-12 xl:col-2">
                            <label for="status" class="block font-bold mb-2">Estado</label>
                            <p-dropdown formControlName="status" [options]="statuses" placeholder="Selecciona un Estado"
                                showClear class="w-full">
                                <ng-template let-option pTemplate="item">
                                    <p-tag [value]="option.value" [severity]="getSeverity(option.label)" />
                                </ng-template>
                            </p-dropdown>
                        </div>
                        <div class="field col-12 xl:col-2">
                            <label for="creationDateFrom" class="block font-bold mb-2">Fecha de Creación Desde</label>
                            <p-calendar id="creationDateFrom" formControlName="creationDateFrom" dateFormat="dd/mm/yy" showIcon></p-calendar>
                        </div>

                        <div class="field col-12 xl:col-2">
                            <label for="creationDateTo" class="block font-bold mb-2">Fecha de Creación Hasta</label>
                            <p-calendar id="creationDateTo" formControlName="creationDateTo" dateFormat="dd/mm/yy" showIcon></p-calendar>
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>

        </div>

        <!-- Botones -->
        <div class="flex justify-content-end gap-2 mt-4">
            <button pButton type="submit" label="Buscar" icon="pi pi-search" class="p-button-primary"></button>
            <button pButton type="button" label="Limpiar" icon="pi pi-times" class="p-button-secondary"
                (click)="resetForm()"></button>
        </div>
    </form>

    <p-table [value]="items" dataKey="itemId" [paginator]="true" [rows]="tableRows" [totalRecords]="totalRecords" [lazy]="true"
        (onLazyLoad)="loadItems($event)" [loading]="loading" [responsiveLayout]="'scroll'" [sortOrder]="-1"
        [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} artículos"
        styleClass="p-datatable-sm p-datatable-gridlines p-datatable-striped">
        <ng-template pTemplate="header">
            <tr>
                <th style="width:10%">
                    Código de Artículo
                </th>
                <th style="width:20%">
                    Descripción
                </th>
                <th style="width:15%">
                    Palet
                </th>
                <th style="width:10%">
                    Cantidad
                </th>
                <th style="width:20%">
                    Cuenta
                </th>
                <th style="width:10%">
                    Estado
                </th>
                <th style="width:10%">
                    Fecha de Creación
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.code }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.pallet?.qrCode }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ item.account?.name }}</td>
                <td><p-tag [value]="item.status" [severity]="getSeverity(item.status)" /></td>
                <td>{{ item.creationDate | date: 'dd/MM/yyyy' }}</td>
            </tr>
        </ng-template>
    </p-table>



</div>


<p-toast></p-toast>