import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../domain/user.model';  // Define el modelo de usuario en `user.model.ts`
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiUrl = environment.apiUrl + '/api/v1/users';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) { }

  createUser(user: User): Observable<User> {
    return this.http.post<User>(this.apiUrl, user, this.httpOptions);
  }

  updateUser(user: User): Observable<User> {
    const url = `${this.apiUrl}/${user.userId}`;
    return this.http.put<User>(url, user, this.httpOptions);
  }

  getAllUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrl);
  }

  deleteUser(user: User): Observable<User> {
    const url = `${this.apiUrl}/${user.userId}`;
    return this.http.delete<User>(url);
  }

  deleteUsers(userIds: number[]): Observable<User> {
    return this.http.delete<User>(`${this.apiUrl}/delete`, { ...this.httpOptions, body: userIds });
  }

  resetPassword(): Observable<{}> {
    const url = `${this.apiUrl}/resetPassword`;
    return this.http.put<User>(url, {}, this.httpOptions);
  }
}